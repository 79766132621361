import { createSlice } from '@reduxjs/toolkit';
import { fetchTopicsList } from './topicsListFetchers';

const initialState = {
	loading: false,
	data: [],
	error: null,
	pagination: {
		'page' : 1,
		'pageSize' : 20,
		'totalElements' : 0
	}
};

export const topicsListSlice = createSlice({
	name: 'topicsList',
	initialState,
	reducers: {
	},
	extraReducers: {
		// getMedialist
		[fetchTopicsList.pending.type]: (state) => {
			state.loading = true;
			state.error = null;
		},
		[fetchTopicsList.fulfilled.type]: (state, action) => {
			state.loading = false;
			state.data = action.payload.data;
			state.pagination = action.payload.pagination;

		},
		[fetchTopicsList.rejected.type]: (state, action) => {
			state.loading = false;
			state.data = [];
			state.error = action.payload;
			state.pagination = initialState.pagination;
		}
	}
});

export default topicsListSlice.reducer;