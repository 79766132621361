import { Button, Card, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import Attachment from '../BasicComponents/Attachment';

export default function Attachments (props) {
	const { selector } = props;
	const { editListItem, addToList, removeFromList, attachments, countryCode, setCountryCode } = selector();

	const handleChange = (id) => ({param, countryCode, value}) => editListItem({list: 'attachments', id, param, countryCode, value});
	const handleAdd = () => {
		const object = {id: new Date().getTime(), description: {}, ref: {}};

		addToList({list: 'attachments', object});
	};

	const handleRemove = (id) => () => removeFromList({list: 'attachments', id});

	return (
		<>
			{	attachments.length > 0 ?
				attachments.map(({ref, description, id}) =>
					<Attachment
						countryCode={countryCode}
						setCountryCode={setCountryCode}
						key={id}
						attachment={ref}
						description={description}
						handleChange={(handleChange(id))}
						remove={handleRemove(id)}
						id={id}
						selector={selector}
					/>
				) :
				<Card
					style={{ marginTop: 16 }}
				>
					This media has no attachments. Add one with the button below.
				</Card>
			}
			<Divider
				style={{borderBlockStartColor: '#000', paddingInline: 50}}
				dashed
			>
				<Button
					size="large"
					shape="circle"
					icon={<PlusOutlined />}
					onClick={handleAdd}
				/>
			</Divider>
		</>);
}