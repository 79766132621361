import './App.css';
import { RouterProvider } from 'react-router-dom';
import router from '@Router';
import { useDispatch, useSelector } from 'react-redux';
import { signIn} from '@Redux/auth/authSlice';
import authSelector from '@Redux/auth/authSelector';
import {setTokensCallback,checkAuthTokens} from '@/utils/sessions';
import useComponentWillMount from './hooks/useComponentWillMount';

import { App as MainApp, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { tokenTimeLeft } from './utils/sessions';
import { logOut } from './redux/auth/authSlice';
import useNotification from '@/hooks/notifications';
import { refreshSession } from './redux/auth/authFetchers';

let authInterval = null;

function App() {
	const dispatch = useDispatch();
	const [showRefreshModal, setShowRefreshModal] = useState(false);
	const {openNotification} = useNotification();
	const {isAuthenticated, loading, error} = useSelector(authSelector);

	useComponentWillMount(
		() => {
			setTokensCallback( tkns => {
				if(!tkns ){
					dispatch(logOut());
					return;
				}
				dispatch(signIn(tkns));
			});
			checkAuthTokens();
		}
	);

	useEffect(() => {
		if (isAuthenticated) {
			authInterval = setInterval(() => {
				const tl = tokenTimeLeft();

				if (tl < 0) {
					return userLogOut();
				}

				if (tl < 300) {
					setShowRefreshModal(true);
				}
			}, 15000);
		} else {
			clearInterval(authInterval);
		}

		return () => {
			clearInterval(authInterval);
		};
	}, [isAuthenticated]);

	function userLogOut () {
		clearInterval(authInterval);
		dispatch(logOut());
		setShowRefreshModal(false);
	}

	useEffect(() => {
		if (error) {
			openNotification({message: error.status, description: error.title, type: 'error'});
		}
	}, [error]);

	return (
		<MainApp>
			<Modal
				title="Warning!"
				open={showRefreshModal}
				okText="Yes"
				onOk={() => dispatch(refreshSession())}
				confirmLoading={loading}
				cancelText="No"
				onCancel={userLogOut}
			>
				<p>Your session will expire in less than 5 minutes. Do you want to keep the session logged in?</p>
			</Modal>
			<RouterProvider router={router} />
		</MainApp>
	);
}

export default App;
