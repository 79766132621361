import MultiLanguageInput from '@Components/BasicComponents/MultiLanguageInput';
import TagList from '@Components/BasicComponents/TagList';
import Validate from '@Components/Validate';

import { required, maxChars, languageRequired } from '@/utils/errors/list';

export default function MainInformation ({selector, hasShortDescription = false}) {
	const {
		title,
		description,
		tags,
		initialTags,
		shortDescription,
		setTags,
		setValue,
		countryCode,
		setCountryCode
	} = selector();

	const handleChange = (param) => {
		return ({countryCode, value}) => setValue({param, countryCode, value});
	};

	return (
		<>
			<h3>Main Information</h3>
			<Validate requirements={[required]}>
				<MultiLanguageInput
					title="Title"
					value={title}
					onChange={handleChange('title')}
					style={{marginVertical: 10}}
					countryCode={countryCode}
					setCountryCode={setCountryCode}
				/>
			</Validate>

			{hasShortDescription &&
				<>
					<br />
					<Validate requirements={[maxChars(500, {isWarning: true}), languageRequired('en_GB')]}>
						<MultiLanguageInput
							title="Short description (max. 500 characters)"
							textArea
							value={shortDescription}
							onChange={handleChange('shortDescription')}
							countryCode={countryCode}
							setCountryCode={setCountryCode}
						/>
					</Validate>
				</>
			}

			<br />
			<Validate requirements={[maxChars(500, {isWarning: true}), languageRequired('en_GB')]}>
				<MultiLanguageInput
					title="Description"
					textArea
					value={description}
					onChange={handleChange('description')}
					countryCode={countryCode}
					setCountryCode={setCountryCode}
				/>
			</Validate>

			<br />
			<TagList
				title="Tags"
				value={tags}
				onChange={setTags}
				initialData={initialTags || []}
			/>
		</>
	);
}