import { createAsyncThunk } from '@reduxjs/toolkit';
import { prepareTagList, prepareList, prepareMediaTypes, prepareCoverages } from '@/utils/basics';
import { getData } from '@/utils/api';
import { getSetting } from '../../utils/settings';


export const fetchTagList = createAsyncThunk(
	'mediaSettings/tagListLoading',

	async (search) => {
		const server = getSetting('REACT_APP_EPMP_BASE_URL')+`/tags?search=${search}&sort=relevancy:desc`;

		const fetchData = await fetch(server, {
			method: 'get',
			headers: { 'Content-Type': 'application/json' }
		})
			.then((response) => {
				if (response.status !== 200) {
					throw response.error;
				} else {
					return response.json();
				}
			})
			.then((json) => prepareTagList(json.content))
			.catch((err) => err);

		return fetchData;
	}
);

export const fetchLanguages = createAsyncThunk(
	'mediaSettings/languagesLoading',

	async (_, {rejectWithValue, getState}) => {
		const {auth: {jwttoken: auth}} = getState();

		const fetchData = async () => {
			try {
				const response = await getData({auth, endpoint: '/languages'});

				if (response.error) {
					return rejectWithValue(response);
				}

				return response.content;

			} catch (err) {
				rejectWithValue(err);
			}
		};

		return await fetchData();
	}
);

export const fetchOwners = createAsyncThunk(
	'mediaSettings/ownersLoading',

	async (_, {rejectWithValue, getState}) => {
		const {auth: {jwttoken: auth}} = getState();

		const fetchData = async () => {
			try {
				const response = await getData({auth, endpoint: '/owners'});

				if (response.error) {
					return rejectWithValue(response);
				}

				return prepareList(response.content.optionsLists);

			} catch (err) {
				rejectWithValue(err);
			}
		};

		return await fetchData();
	}
);

export const fetchMediaTypes = createAsyncThunk(
	'mediaSettings/mediaTypesLoading',

	async (_, {rejectWithValue, getState}) => {
		const {auth: {jwttoken: auth}} = getState();

		const fetchData = async () => {
			try {
				const response = await getData({auth, endpoint: '/mediaTypes'});

				if (response.error) {
					return rejectWithValue(response);
				}

				return prepareMediaTypes(response.content.optionsLists);

			} catch (err) {
				rejectWithValue(err);
			}
		};

		return await fetchData();
	}
);

export const fetchCoverages = createAsyncThunk(
	'mediaSettings/coverages',

	async (_, {rejectWithValue, getState}) => {
		const {auth: {jwttoken: auth}} = getState();

		const fetchData = async () => {
			try {
				const response = await getData({auth, endpoint: '/coverages'});

				if (response.error) {
					return rejectWithValue(response);
				}

				return prepareCoverages(response.content.optionsLists);

			} catch (err) {
				rejectWithValue(err);
			}
		};

		return await fetchData();
	}
);