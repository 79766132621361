import { useRef, createContext } from "react";

export const ErrorsContext = createContext({});

export function ErrorsContextProvider({ children }) {
  const verifyFunctions = useRef({});
  
  function addVerifyFunction({id, func}) {
    const err = {...verifyFunctions.current, [id]: func};

    verifyFunctions.current = err;
  }
  
  function resetErrors() {
    verifyFunctions.current = {};
  }
  

  return (
    <ErrorsContext.Provider
      value={{
        verifyFunctions,
        addVerifyFunction,
        resetErrors
      }}
    >
    {children}
    </ErrorsContext.Provider>
  );
}