import { putData } from '@/utils/api';
import { checkAuthTokens } from '@/utils/sessions';

export const highlightItem =  async ({url, id, highlighted = true, position = null}) => {
  try {
    const { jwttoken } = checkAuthTokens();

    let variables = `highlighted=${highlighted}`;
    if (highlighted && position !== null) {
      variables += `&position=${position}`;
    }
    const response = await putData({auth: jwttoken, endpoint: `/${url}/${id}?${variables}`});

    if (response.error) {
      throw(new Error(response.message));
    }
    return {error: false};

  } catch (err) {
    return { error: true, message: err.message};
  }
};

export const orderHighlighted =  async ({url, ids}) => {
  try {
    const { jwttoken } = checkAuthTokens();

    let endpoint = url + '?';

    ids.forEach((id, index) => {
      if (index !== 0) {
        endpoint += '&';
      }

      endpoint += 'mediasIds=' + id;
    });

    const response = await putData({auth: jwttoken, endpoint});

    if (response.error) {
      throw(new Error(response.message));
    }
    return {error: false};

  } catch (err) {
    console.log(err);
    return { error: true, message: err.message};
  }
};