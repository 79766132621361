import { Children, cloneElement, useContext, useEffect, useState } from 'react';
import { Button, Space, Table } from 'antd';
import Icon, { MenuOutlined } from '@ant-design/icons';

import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import ExternalLink from '@Components/icons/ExternalLink';

import { localTimeFormated } from '@/utils/dates';
import { getPreview } from '@/utils/settings';
import { pluralTosingular } from '@/utils/plurals';

import { GoBackContext } from '@/context/goBackContext';

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {Children.map(children, (child) => {
        if (child.key === 'sort') {
          return cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: 'none',
                  cursor: 'move',
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};
export default function SortableTable ({data, loading, saveItems, singleDate = true, updateDate = false, idKey = 'epId', name = ''}) {
  const columns =[
		{
			key: 'sort',
			width: 100,
			align: 'center',
		},
		{
			title: 'Reference ID',
			dataIndex: 'epId',
			key: 'epId',
			width: 200,
		},
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
			style: { wordWrap: 'break-word', wordBreak: 'break-word' }
		},
		(
			!updateDate ? {
        title: !singleDate ? 'Start Date' : 'Media Date',
        dataIndex: 'eventDate',
        key: 'eventDate',
        render: (_, item) => {
          return localTimeFormated(item.eventDate);
        },
        width: 150,
      } : {}
    ),
    (
			updateDate ? {
				title: 'Update Date',
				dataIndex: 'updateDate',
				key: 'updateDate',
				render: (_, item) => {
					return localTimeFormated(item.updateDate);
				},
				width: 150,
			} : {}
		),
		(
			!singleDate ? {
				title: 'End Date',
				dataIndex: 'eventEndDate',
				key: 'eventEndDate',
				render: (_, item) => {
					return localTimeFormated(item.eventDate);
				},
				width: 150,
			} : {}
		),
    {
      align: 'center',
			key: 'action',
			width: 100,
			render: (_, element) => (
				<Space size="middle">
					<Button
						href={getPreview(pluralTosingular(name), element.epId)}
						target='_blank'
						style={{paddingInline: 5}}
					>
						<Icon component={ExternalLink} color='black'  />
					</Button>
				</Space>
			),
		},
	];

  const [dataSource, setDataSource] = useState([]);
  const { areChanges, setAreChanges } = useContext(GoBackContext);

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });

      setAreChanges(true);
    }
  };

  function onSave () {
    console.log(dataSource);
    const items = [...dataSource].map((item, i) => { return {id: item[idKey], position: i }; });
    saveItems(items);
    setAreChanges(false);
  }

  return (
    <>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey="key"
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            pagination={false}
          />
        </SortableContext>
      </DndContext>
      <Space size="large" direction="vertical" style={{width: '100%', marginTop: 20}} align="center">
        <Button
          type="primary"
          size='large'
          onClick={onSave}
          disabled={!areChanges}
        >
          Save changes
        </Button>
      </Space>
    </>
  );
}