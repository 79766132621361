import { Button, ConfigProvider, Select, Space, Table } from 'antd';
import Icon, {CloseOutlined, CheckOutlined} from '@ant-design/icons';


import ExternalLink from '@Components/icons/ExternalLink';

import { localTimeFormated } from '@/utils/dates';
import { getPreview } from '@/utils/settings';
import BasicEmpty from '@Components/BasicComponents/BasicEmpty';

const orderTypes = [
	{ label: 'Relevancy', value: 'relevancy:asc' },
	{ label: 'Event Date: newest', value: 'mediaDate:desc' },
	{ label: 'Event Date: oldest', value: 'mediaDate:asc' },
	{ label: 'Last Update: newest', value: 'modified:desc' },
	{ label: 'Last Update: oldest', value: 'modified:asc' },
];

const buttonStyles = { fontSize: '16px', display: 'flex', alignItems: 'center' };

export default function HighlightedTable ({data, loading, sort, setSort, select, unselect, type}) {
	const columns = [
		{
			title: 'Reference ID',
			dataIndex: 'epId',
			key: 'epId',
			width: 200,
		},
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
			style: { wordWrap: 'break-word', wordBreak: 'break-word' }
		},
		{
			title: 'Media Date',
			dataIndex: 'eventDate',
			key: 'eventDate',
			render: (_, item) => {
				return localTimeFormated(item.eventDate);
			},
			width: 150,
		},
		{
			title: <label  style={{width: '100%'}}>Order by: <Select options={orderTypes} value={sort} onChange={setSort} size='Large' style={{width: 170}}/></label>,
			key: 'action',
			width: 300,
			render: (_, element) => (
				<Space size="middle">
					{element.selected ?
						<Button
							style={buttonStyles}
							onClick={() => unselect(element.mediaId || element.key)}
							type="primary"
						>
							<CloseOutlined />
							Unselect
						</Button> :
						<Button
							style={buttonStyles}
							onClick={() => select(element.mediaId || element.key)}
						>
							<CheckOutlined />
							Select
						</Button>
					}
					<Button
						href={getPreview(type, element.epId)}
						target='_blank'
						style={{paddingInline: 5}}
					>
						<Icon component={ExternalLink} color='black'  />
					</Button>
				</Space>
			),
		},
	];

	return (
		<ConfigProvider renderEmpty={() => BasicEmpty({text:"No results"})}> 
			<Table
				rowKey="key"
				dataSource={data}
				columns={columns}
				loading={loading}
				pagination={false}
			/>
		</ConfigProvider>
	);
}