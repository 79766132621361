import { useEffect } from 'react';

import { Input, Pagination, Row, Select, Space, Typography } from 'antd';
const { Search } = Input;
const { Title } = Typography;

import useNotification from '@/hooks/notifications';
import useFetchData from '@/hooks/useFetchData';
import TopicsTable from '@Components/Tables/TopicsTable';

const searchTypes = [
	{ label: 'Search by reference ID', value: 'topicKey' },
	{ label: 'Search by keywords', value: 'search' },
];

export default function Topics() {
	const {openNotification} = useNotification();

	const params = {type: 'topics', sort: 'modified:desc'};
	const {setParam, setParams, searchParams, getData, data, loading, pagination, error, deleteItem} = useFetchData({params, newFunctions:true});

	useEffect(() => {
		if (error) {
			openNotification({message: 'Something went wrong!', description: error.message || null, type: 'error'});
		}
	}, [error]);

	const handlePagination = (page, pageSize) => {
		setParams({page, pageSize});
	};

	const handleSearch = () => {
		setParams({sort: 'relevancy:asc'});
	};

	return (
		<Space size="large" direction='vertical' style={{width: '100%'}} >
			<Title>All topics</Title>
			<Search
				addonBefore={
					<Select
						style={{width: 190}}
						options={searchTypes}
						value={searchParams.get("searchType") || 'search'}
						onChange={setParam('searchType')}
					/>
				}
				value={searchParams.get("search") || ''}
				onChange={setParam('search', false)}
				onSearch={handleSearch}
				enterButton
			/>
			<>
				<TopicsTable
					data={data}
					loading={loading}
					sort={searchParams.get("sort") || 'modified:desc'}
					setSort={setParam('sort')}
					getListData={getData}
					deleteItem={deleteItem}
				/>
				<Row justify="center">
					<Pagination
						total={pagination.totalElements}
						pageSize={pagination.pageSize}
						current={pagination.page}
						showSizeChanger
						showQuickJumper
						hideOnSinglePage
						disabled={loading}
						onChange={handlePagination}
					/>
				</Row>
			</>
		</Space>
	);
}