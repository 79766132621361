import { useContext } from 'react';
import { MediaContext } from '@/context/mediaContext/mediaContext';
import MainInformation from '@Components/ComposedComponents/MainInformation';
import PublishingDates from '@Components/ComposedComponents/PublishingDates';
import SEOInformation from '@Components/ComposedComponents/SEOInformation';
import CopyrightInformation from '@Components/ComposedComponents/CopyrightInformation';
import LinksAndAttachments from '@Components/ComposedComponents/LinksAndAttachments';
import Transcript from '@Components/ComposedComponents/Transcript';
import ContentProperty from '@Components/ComposedComponents/ContentProperty';
import UploadButtons from '@Components/ComposedComponents/UploadButtons';
import ImageUpload from '@Components/ComposedComponents/ImageUpload';


import { checkThumbnailsNames } from '@/utils/names';

const thumbnailTooltip = `The filename must use the following convention for thumbnails files: {referenceID}_{language-code}_thumbnail.jpg`;

export default function PodcastForm () {
	const selector = () => useContext(MediaContext);

	const props = {
		selector,
	};

	return (
		<>
			<MainInformation {...props}/>
			<br/><br/>
			<SEOInformation {...props}/>
			<br/><br/>
			<PublishingDates {...props}/>
			<br/><br/>
			<CopyrightInformation {...props}/>
			<br/><br/>
			<LinksAndAttachments {...props}/>
			<br/><br/>
			<ImageUpload
				title="Thumbnail podcast upload"
				tooltip={thumbnailTooltip}
				checkNames={checkThumbnailsNames}
				{...props}
			/>
			<br/><br/>
			<Transcript {...props}/>
			<br/><br/>
			<ContentProperty {...props}/>
			<br/><br/>
			<UploadButtons {...props}/>
		</>
		
	);
}