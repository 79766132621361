import { useContext } from 'react';
import MainInformation from '@Components/ComposedComponents/MainInformation';
import SEOInformation from '@Components/ComposedComponents/SEOInformation';
import InfographicUpload from '@Components/ComposedComponents/InfographicUpload';
import UploadButtons from '@Components/ComposedComponents/UploadButtons';
import PublishingDates from '@Components/ComposedComponents/PublishingDates';
import CopyrightInformation from '@Components/ComposedComponents/CopyrightInformation';
import LinksAndAttachments from '@Components/ComposedComponents/LinksAndAttachments';
import ContentProperty from '@Components/ComposedComponents/ContentProperty';
import ZipUpload from '@Components/ComposedComponents/ZipUpload';
import { MediaContext } from '@/context/mediaContext/mediaContext';



export default function InfographicForm ({interactive = false}) {
	const selector = () => useContext(MediaContext);

	const props = {
		selector,
	};

	return (
		<>
			<MainInformation {...props}/>
			<br/><br/>
			<SEOInformation {...props}/>
            <br/><br/>
            <PublishingDates {...props}/>
			<br/><br/>
			{ !interactive ?
				<>
					<CopyrightInformation {...props}/>
					<br/><br/>
					<LinksAndAttachments {...props}/>
					<br/><br/>
					<InfographicUpload {...props}/>
				</> :
				<ZipUpload {...props}/>
			}
            <br/><br/>
			<ContentProperty {...props}/>
			<br/><br/>
			<UploadButtons {...props}/>
		</>
		
	);
}