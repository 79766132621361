import { simpleToFullLanguage } from "../../languages";
import { getLang } from "../../names";

export const transformAttachments = ({links, attachments}) => {
	links = getValidLinks(links).map(link => {
		return {
			ref: link.ref,
			description: link.description,
			format: 'link'
		};
	});

	attachments = getValidLinks(attachments).map(attachment => {
		return {
			ref: transformAttachmentRef(attachment.ref),
			description: attachment.description,
			format: 'attachment'
		};
	});

	return [...links, ...attachments];
};

const getValidLinks = (links) => {
	return links.filter(link => Object.keys(link.ref).length !== 0);
};

const transformAttachmentRef = (ref) => {
	const res = {};
	Object.keys(ref).forEach(country => {
		res[country] = ref[country].url;
	});

	return res;
};

export const transformFiles = (files) => {
	const description = {};
	const ref = {};

	files.forEach(file => {
		let lang ='';
		if (file.lang) {
			lang = file.lang;
		} else {
			const shortLang = getLang(file.name);
			lang = simpleToFullLanguage[shortLang.toLowerCase()];
		}
		
		description[lang] = file.name;
		ref[lang] = file.url;
	});

	return [{description, ref}];
};

export const transformSimpleFiles = (files) => {
	const newFiles = {};

	files.forEach(file => {
		let lang ='';
		if (file.lang) {
			lang = file.lang;
		} else {
			const shortLang = getLang(file.name);
			lang = simpleToFullLanguage[shortLang.toLowerCase()];
		}
		
		newFiles[lang] = file.url;
	});

	return newFiles;
};

export const transformMultilanguageFiles = (multilanguageFiles) => {
	const files = [];

	Object.values(multilanguageFiles).forEach(typeFiles => {
		Object.values(typeFiles).forEach(languageFiles => {
			Object.values(languageFiles).forEach(file => {
				const {name, url, countryCode, type} = file;

				files.push(
					{
						name,
						url,
						format: type,
						language: countryCode
					}
				);
			});
		});
	});

	return files;
};

export const transformTags = (tags) => {
	if (!tags) {
		return [];
	}

	return tags.map(tag => tag?.value ? tag.value : tag);
};

export const transformAttachmentsNew = (attachments) => {
	attachments = getValidLinks(attachments).map(attachment => {
		return {
			refs: transformMultilanguageObject(attachment.ref),
			descriptions: transformMultilanguageObject(attachment.description),
			format: 'attachment'
		};
	});

	return attachments;
};

export const transformLinks = (links) => {
	links = getValidLinks(links).map(link => {
		return {
			refs: transformMultilanguageObject(link.ref),
			descriptions: transformMultilanguageObject(link.description),
		};
	});

	return links;
};

export const transformMultilanguageObject = (multilanguageObject) => {
	const res = [];

  Object.keys(multilanguageObject).forEach(key => {
    res.push({
			languageIsoCode: key,
			value: multilanguageObject[key].url || multilanguageObject[key]
		});
  });

  return res;
};
