import { useContext } from 'react';

import useNotification from '@/hooks/notifications';
import useFindErrors from '@/hooks/useFindErrors';

import { ErrorsContext } from '@/context/errorsContext';

import { Button, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';

export default function UploadButtons ({selector}) {
	const navigate = useNavigate();

	const {process: {edit}, upload, setInitialState} = selector();

	const {findErrors} = useFindErrors();
	const {openNotification} = useNotification();
	const { resetErrors } = useContext(ErrorsContext);

	function handleCancel () {
		if (edit) {
			navigate(-1);
		} else {
			setInitialState();
			resetErrors();
		}
	}

	function hanldeUpload () {
		const {ok, errors} = findErrors();

		if(!ok) {
			openNotification({message: 'Errors in the form!' , description: errors[0], type: 'error'});
		} else {
			upload();
			resetErrors();
		}
	}
	const style = {
		width: 200,
		padding: '5px 10px',
		borderRadius: '100px'
	};
	return (
		<Row justify="center" gutter={[64, 16]}>
			<Col>
				<Button onClick={handleCancel} danger type="primary" size='large' style={style}>Cancel</Button>
			</Col>
			<Col>
				<Button onClick={hanldeUpload} type="primary" size='large' style={style}>Confirm Upload</Button>
			</Col>
		</Row>
	);
}