import { Button, Col, Modal, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';

const {Text} = Typography;

import useIbmS3Uploader from '@/hooks/useIbmS3UploaderNew';
import useNotification from '@/hooks/notifications';

import 'react-image-crop/dist/ReactCrop.css';
import { cropImage } from '../../utils/images';
import Validate from '../Validate';
import { required } from '../../utils/errors/list';


export default function CropImage({ selector }) {
  const { files, coverImage, setSimpleFile, removeSimpleFile } = selector();

  const useIbmS3UploaderRef = useRef(useIbmS3Uploader({
    setFile: setSimpleFile('coverImage'),
    removeFile: removeSimpleFile('coverImage'),
    bucketPrefix: 'topics/images/',
  }));
	const {handleUpload, handleRemove} = useIbmS3UploaderRef.current;
  const imageRef = useRef(null);
  const [crop, setCrop] = useState();
  const [openCrop, setOpenCrop] = useState();
  const {openNotification} = useNotification();

  const getImageMetadata = (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  useEffect(() => {
    if(files[0]?.url) {
      getImageMetadata(files[0]?.url, (err, img) => {
        if (err) {
          console.error(err);
          return;
        }

        let width = 100;
        let height = (50 / img.naturalHeight) * img.naturalWidth;
        let x = 0;
        let y = (100 - height) / 2;

        if (height > 100) {
          height = 100;
          width = (200 / img.naturalWidth) * img.naturalHeight;
          y = 0;
          x = (100 - width) / 2;
        }

        setCrop({unit: '%', x, y, width, height});
      });
    }
  }, [files[0]?.url]);

  const onUpload = async () => {
    const img = imageRef.current;
    img.crossOrigin = '*';

    if (!img) {
      return;
    }

    if (coverImage) {
      handleRemove(coverImage);
    }

    if (img.naturalWidth == 0 || img.naturalHeight == 0) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    let cropedImage = null;

    try {
      cropedImage = await cropImage({img, crop});
    } catch (e) {
      openNotification({message: 'Something went wrong!', description: 'Try again later.', type: 'error'});
      setOpenCrop(false);
      return;
    }

    setOpenCrop(false);

    handleUpload({file: cropedImage});

  };

  const CropButton = ({error, disabled}) => {
    return <>
      <Button onClick={() => setOpenCrop(true)} type='primary' disabled={disabled}>Crop Image</Button>
      {(!error.isOk && !disabled) &&
        <Col span={16}>
          <Text type={error.type === 'error' ? 'danger' : 'warning'}>{error?.text}</Text>
        </Col>
      }
    </>;
  };

  return (
    <>
      <Validate requirements={[required]}>
          <CropButton value={coverImage?.url} title="Crop Image" disabled={!files[0]?.url}/>
      </Validate>

      <Modal width="fit-content"  open={openCrop} onCancel={() => setOpenCrop(false)} onOk={onUpload}>
        <ReactCrop
          width="100%"
          crop={crop}
          onChange={c => setCrop(c)}
          aspect={1200 / 480}
        >
          <img width="100%" src={files[0]?.url} ref={imageRef}/>
        </ReactCrop>
      </Modal>
      <br />
      <br />
      <img width="100%" src={coverImage?.url} ref={imageRef}/>
    </>
  );
}

