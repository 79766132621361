import React, { useContext, useState } from 'react';

import { Space, Tabs } from 'antd';
import Selection from './Selection';
import Order from './Order';

import { GoBackContext } from '@/context/goBackContext';

export default function Details ({name}) {
	const [ activeKey, setActiveKey ] = useState('1');
	const { setIsModalOpen, setOkCb, areChanges } = useContext(GoBackContext);

	const items = [
		{
				key: '1',
				label:
        <span
          style={{
            textTransform: 'uppercase'
          }}
        >
          {name + ' SELECTION'}
        </span>,
				children: <Selection name={name} />,
		},
		{
				key: '2',
				label:
        <span
          style={{
            textTransform: 'uppercase'
          }}
        >
          {'ORDER SELECTED ' + name}
        </span>,
				children: <Order name={name} />,
		},
	];

	const handleChange = ( key ) => {
		if ( !areChanges ) {
			setActiveKey( key );
			return;
		}

		setActiveKey(activeKey);
		setIsModalOpen(true);
		setOkCb(() => setActiveKey(key));
	};

	return (
		<>
			<Space size="large" direction='vertical' style={{width: '100%'}} >
        <Tabs
					activeKey={activeKey}
					onChange={handleChange}
					items={items}
					type="line"
					size="large"
					destroyInactiveTabPane
				/>
			</Space>
		</>
	);
}