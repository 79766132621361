import { Col, Row } from 'antd';

import BasicDatePicker from '@Components/BasicComponents/BasicDatePicker';

import { localTimeToUtc } from '@/utils/dates';



export default function PublishingDates ({simple, selector}) {
	const {eventDate, startPublicationDate, endPublicationDate, /* startEmbargoDate, endEmbargoDate, */ setValue } = selector();

	const handleChange = (param) => {
			return (value) => setValue({param, value: value ? localTimeToUtc(value).format() : null});
	};

	return (
		<>
			<h3>Publishing Dates</h3>
			<Row gutter={[16, 24]} justify="start">
				{simple || <Col className="gutter-row" md={24} lg={12} xl={8}>
					<BasicDatePicker
						title="Event date"
						value={eventDate}
						onChange={handleChange('eventDate')}
					/>
				</Col>}
				<Col className="gutter-row" md={24} lg={12} xl={8}>
					<BasicDatePicker
						title="Start date"
						value={startPublicationDate}
						onChange={handleChange('startPublicationDate')}
						maxDate={endPublicationDate}
					/>
				</Col>
				<Col className="gutter-row" md={24} lg={12} xl={8}>
					<BasicDatePicker
						title="End date"
						value={endPublicationDate}
						onChange={handleChange('endPublicationDate')}
						minDate={startPublicationDate}
					/>
				</Col>
				{/* <Col className="gutter-row" md={24} lg={12} xl={8}>
					<BasicDatePicker
						title="Embargo start"
						value={startEmbargoDate}
						onChange={handleChange('startEmbargoDate')}
					/>
				</Col>
				<Col className="gutter-row" md={24} lg={12} xl={8}>
					<BasicDatePicker
						title="Embargo end"
						value={endEmbargoDate}
						onChange={handleChange('endEmbargoDate')}
					/>
				</Col> */}
			</Row>
		</>
		
	);
}