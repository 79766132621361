import { createSlice } from '@reduxjs/toolkit';

import { getItemData } from './eventInfoFetcher.js';

const initialState = {
	process: {
		loading: false,
		error: null,
		succeed: false
	},
	mediaType: null,
	category: null,
	epId: '',
	eventId: '',
	title: {},
	description: {},
	tags: [],
	location: '',
	seoTitle: {},
	seoDescription: {},
	seoKeywords: {},
	eventDate: null,
	eventEndDate: null,
	modifiedDate: null,
	startPublicationDate: null,
	endPublicationDate: null,
	startEmbargoDate: null,
	endEmbargoDate: null,
	termsOfUse: {},
	copyrightAuthor: {},
	copyrightYear: '',
	copyrightLicense: {},
	links:[],
	owner: '',
	creationDate: null,
	updateDate: null,
	associatedMedia: {
		loading: false,
		error: false,
		succeed: false,
		data: []
	},
	associatedTopics: {
		loading: false,
		error: false,
		succeed: false,
		data: null,
		automatic: null
	},
	highlightedMedia: {
		loading: false,
		error: false,
		succeed: false,
		data: []
	},
};

export const eventInfoSlice = createSlice({
	name: 'eventInfo',
	initialState: {...initialState},
	reducers: {
		setInitialState () {
			return initialState;
		},
		setTags (state, action) {
			return {
        ...state,
        tags: action.payload
      };
		}
	},
	extraReducers: {
		// getItemData
		[getItemData.pending.type]: () => {
			return {
				...initialState,
				process: {
					loading: true,
					error: null,
					succeed: false
				}
			};
		},
		[getItemData.fulfilled.type]: (state, action) => {
			return {
				...initialState,
				...action.payload,
				process: {
					edit: true,
					loading: false,
					error: null,
					succeed: true
				},
			};
		},
		[getItemData.rejected.type]: (state, action) => {
			state.process = {
				loading: false,
				error: action.payload,
				succeed: false
			};
		}
	}
});

export const { setInitialState, setTags } = eventInfoSlice.actions;

export default eventInfoSlice.reducer;