import { useParams } from 'react-router-dom';
import { Space, Tabs} from 'antd';
import { useSelector } from 'react-redux';

import eventInfoSelector from '@Redux/eventInfo/eventInfoSelector';

import EventInfo from '@Components/Event/Info';
import Associated from '@Components/Event/Associated';
import AssociatedMediasTable from '@Components/Tables/AssociatedMediasTable';
import AssociatedTopicsTable from '@Components/Event/AssociatedTopicsTable';
import HighlightedMedia from '@Components/Event/HighlightedMedia';

export default function Details() {
    const { epId } = useParams();

    const {eventId} = useSelector(eventInfoSelector());

    const items = [
        {
            key: '1',
            label: 'EVENT INFO',
            children: <EventInfo epId={epId}/>,
        },
        {
            key: '2',
            label: 'ASSOCIATED MEDIA',
            children: <Associated name="MEDIA" table={AssociatedMediasTable} otherComponent={UnderConstruction}/>,
            destroyInactiveTabPane: true,
            disabled: !eventId
        },
        {
            key: '3',
            label: 'ASSOCIATED TOPICS',
            children: <Associated name="TOPICS" table={AssociatedTopicsTable} otherComponent={AssociatedTopicsByTag}/>,
            destroyInactiveTabPane: true,
            disabled: !eventId
        },
        {
            key: '4',
            label: 'HIGHLIGHTED MEDIA',
            children: <HighlightedMedia />,
            destroyInactiveTabPane: true,
            disabled: !eventId
        },
    ];

	return (
		<>
			<Space size="large" direction='vertical' style={{width: '100%'}} >
				<h1>{`Events > ${epId}`}</h1>
			</Space>
            <Tabs defaultActiveKey="1" items={items} type="card" size="large"/>
		</>
	);
}

function AssociatedTopicsByTag () {
    return <AssociatedTopicsTable automatic />;
}

function UnderConstruction () {
    return <p>Under construction</p>;
}