import { createSlice } from '@reduxjs/toolkit';
import { login, refreshSession } from './authFetchers';
import { deleteTokens  } from '@/utils/sessions';

export const authSlice = createSlice({
	name: 'auth',
	initialState: {
		loading: false,
		isAuthenticated: false,
		jwttoken: '',
		refreshToken: '',
		error: null,
	},
	reducers: {
		logOut: (state) => {
			deleteTokens();
			return {
				...state,
				loading: false,
				isAuthenticated: false,
				jwttoken: '',
				refreshToken: '',
				error: null,
			};
		},
		signIn: (state, action) => {
			const { jwttoken, refreshToken } = action.payload;
			return {
				...state,
				isAuthenticated: true,
				jwttoken,
				refreshToken,
			};
		}
	},
	extraReducers: {
		[login.pending.type]: (state) => {
			return {
				...state,
				loading: true,
				error: null
			};
		},
		[login.fulfilled.type]: (state, action) => {
			const { jwttoken, refreshToken } = action.payload;
			return {
				loading: false,
				isAuthenticated: true,
				jwttoken,
				refreshToken,
				error: null
			};
		},
		[login.rejected.type]: (state, action) => {
			return {
				loading: false,
				isAuthenticated: false,
				jwttoken: '',
				refreshToken: '',
				error: action.payload
			};
		},
		[refreshSession.pending.type]: (state) => {
			return {
				...state,
				loading: true,
				error: null
			};
		},
		[refreshSession.fulfilled.type]: (state, action) => {
			const { jwttoken, refreshToken } = action.payload;
			return {
				loading: false,
				isAuthenticated: true,
				jwttoken,
				refreshToken,
				error: null
			};
		},
		[refreshSession.rejected.type]: (state, action) => {
			return {
				loading: false,
				isAuthenticated: false,
				jwttoken: '',
				refreshToken: '',
				error: action.payload
			};
		}
	}
});

export const { logOut , signIn } = authSlice.actions;

export default authSlice.reducer;