import { useContext, useState } from "react";
import { Select, Spin, Tabs } from "antd";

import { TopicContext } from '@/context/topicContext/topicContext';

import HighlightedMediasTable from "@Components/Topics/HighlightedMediasTable";
import OrderHighlightedMedias from "@Components/Topics/OrderHighlightedMedias";

const options = [
    {label: 'UNSELECTED MEDIA', value: 'unselected'},
    {label: 'SELECTED MEDIA', value: 'selected'}
];

export default function HighlightedMedia () {
    const [option, setOption] = useState('selected');
    const { topicId } = useContext(TopicContext);

    const items = [
        {
            key: '1',
            label: <span>HIGHLIGHTED MEDIA <Select
                    options={options}
                    value={option}
                    onChange={setOption}
                    style={{width: 200, marginLeft: 10}}
                /></span>,
            children: <HighlightedMediasTable
                selected={option === 'selected'}
                url={`topics/${topicId}/medias/highlighted`}
                showSearch={false}
            />,
        },
        {
            key: '2',
            label: 'ORDER HIGHLIGHTED MEDIA',
            children: <OrderHighlightedMedias url={`topics/${topicId}/medias`} />,
            destroyInactiveTabPane: true,
        },
    ];

    return<Spin spinning={false}>
            <Tabs defaultActiveKey="1" items={items} size="large" destroyInactiveTabPane />
    </Spin>;
}