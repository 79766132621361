import { createSlice } from '@reduxjs/toolkit';

import { fetchElectionsEvents, fetchElectionsTopics, selectItem, unselectItem, itemPosition} from './electionsFetcher.js';

const initialState = {
	process: {
		loading: false,
    error: null,
    succeed: false,
	},
	selectedTopics: {
		loading: false,
		error: false,
		succeed: false,
		data: []
	},
	selectedEvents: {
		loading: false,
		error: false,
		succeed: false,
		data: []
	},
};

export const electionsSlice = createSlice({
	name: 'elections',
	initialState: {...initialState},
	reducers: {
		setInitialState () {
			return initialState;
		},
	},
	extraReducers: {
		// selectedEvents
		[fetchElectionsEvents.pending.type]: (state) => {
			state.selectedEvents = {
				loading: true,
				succeed: false,
				error: false,
				data: state.selectedEvents.data,
			};
		},
		[fetchElectionsEvents.fulfilled.type]: (state, action) => {
			state.selectedEvents = {
				loading: false,
				succeed: true,
				error: false,
				data: action.payload.data,
			};
		},
		[fetchElectionsEvents.rejected.type]: (state, action) => {
			state.selectedEvents = {
				loading: false,
				succeed: false,
				error: action.payload,
				data: state.selectedEvents.data,
			};
		},
		// selectedTopics
		[fetchElectionsTopics.pending.type]: (state) => {
			state.selectedTopics = {
				loading: true,
				succeed: false,
				error: false,
				data: state.selectedTopics.data,
			};
		},
		[fetchElectionsTopics.fulfilled.type]: (state, action) => {
			state.selectedTopics = {
				loading: false,
				succeed: true,
				error: false,
				data: action.payload.data,
			};
		},
		[fetchElectionsTopics.rejected.type]: (state, action) => {
			state.selectedTopics = {
				loading: false,
				succeed: false,
				error: action.payload,
				data: state.selectedTopics.data,
			};
		},
		// select media
		[selectItem.pending.type]: (state) => {
			state.process.loading = true;
			state.process.error = null;
		},
		[selectItem.fulfilled.type]: (state) => {
			state.process.loading = false;
		},
		[selectItem.rejected.type]: (state, action) => {
			state.process.loading = false;
			state.process.error = action.payload;
		},
		// unselect media
		[unselectItem.pending.type]: (state) => {
			state.process.loading = true;
			state.process.error = null;
		},
		[unselectItem.fulfilled.type]: (state) => {
			state.process.loading = false;
		},
		[unselectItem.rejected.type]: (state, action) => {
			state.process.loading = false;
			state.process.error = action.payload;
		},
		// itemPosition
		[itemPosition.pending.type]: (state) => {
			state.process.loading = true;
			state.process.error = null;
		},
		[itemPosition.fulfilled.type]: (state) => {
			state.process.loading = false;
		},
		[itemPosition.rejected.type]: (state, action) => {
			state.process.loading = false;
			state.process.error = action.payload;
		},
	}
});

export const { setInitialState } = electionsSlice.actions;

export default electionsSlice.reducer;