import React, { useEffect } from 'react';

import { Outlet,  useNavigate } from 'react-router-dom';
import * as ROUTES from '@Router/routes';
import { useDispatch, useSelector } from 'react-redux';

import authSelector from '@Redux/auth/authSelector';
import { logOut } from '@Redux/auth/authSlice';
import { fetchLanguages, fetchOwners, fetchMediaTypes, fetchCoverages } from '@Redux/mediaSettings/mediaSettingsFetchers';
import mediaSettingsSelector from '@Redux/mediaSettings/mediaSettingsSelector';

import { Layout, Space } from 'antd';
import Icon from '@ant-design/icons';
import { Typography } from 'antd';

const { Title } = Typography;
const { Header, Content } = Layout;

import SideMenu from '@Components/SideMenu';
import LogOut from '@Components/icons/LogOut';
import UserIcon from '@Components/icons/UserIcon';
import HomeIcon from '@Components/icons/Home';

import { parseRelativeUrl } from '@/utils/settings';

import logo from '@/images/logo-ep-transp-white.png';
import useRunOnFirstMount from '@/hooks/useRunOnFirstMount';


export default function Home() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { isAuthenticated } = useSelector(authSelector);
	const { languages, contentOwners, mediaTypes, coverages } = useSelector(mediaSettingsSelector);

	useEffect(() => {
		if (!isAuthenticated) {
			navigate(ROUTES.LOGIN);
			return;
		}
	}, [isAuthenticated]);

	useRunOnFirstMount(() => {
		if (languages.data?.length === 0 && !languages.loading) {
			dispatch(fetchLanguages());
		}

		if (contentOwners.data?.length === 0 && !contentOwners.loading) {
			dispatch(fetchOwners());
		}

		if (mediaTypes.data?.length === 0 && !mediaTypes.loading) {
			dispatch(fetchMediaTypes());
		}

		if (coverages.data?.length === 0 && !coverages.loading) {
			dispatch(fetchCoverages());
		}
	});

	return (
		<Layout style={{height: '100vh'}}>
			<Header className='app-header' >
					<div className='app-logo'>
						<img src={parseRelativeUrl(logo)} alt='' style={{width: 120}}/>
						<Title className='app-header__title'> Multimedia Centre - Content Management System</Title>
					</div>
					<Space size="large"  className='app-header__icon-container'>
						<Icon component={HomeIcon} onClick={() =>  navigate(ROUTES.HOME)} />
						<Icon component={UserIcon} onClick={() =>  {}} />
						<Icon component={LogOut} onClick={() => dispatch(logOut())} />
					</Space>
			</Header>
			<Layout className='app-layout'>
				<SideMenu/>
				<Layout className='main-layout'>
					<Content className='main-content'>
						<Outlet />
					</Content>
				</Layout>
			</Layout>
		</Layout>
	);
}