import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LockOutlined, UserOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Button, Form, Input,  Row, Space, Spin } from 'antd';

import * as ROUTES from '@Router/routes';
import authSelector from '@Redux/auth/authSelector';
import { login } from '@Redux/auth/authFetchers';
import useNotification from '@/hooks/notifications';
import { parseRelativeUrl } from '@/utils/settings';

import logo from '@/images/logo-ep-transp-color.png'; 
import { getSetting } from '../utils/settings';

function Login() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { isAuthenticated, loading, error } = useSelector(authSelector);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const {openNotification} = useNotification();

	useEffect(() => {
		if (isAuthenticated) {
			navigate(ROUTES.HOME, { replace: true });
		}
	}, [isAuthenticated]);

	useEffect(() => {
		if (error) {
			openNotification({message: error.status, description: error.title, type: 'error'});
		}
	}, [error]);

	function handleSubmit() {
		dispatch(login({username, password}));
	}
	function handleUserChange(e) {
		e.preventDefault();
		setUsername(e.target.value);
	}
	function handlePasswordChange(e) {
		e.preventDefault();
		setPassword(e.target.value);
	}

	return (
		<>
			<Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}}>
				<Space direction='vertical' size='large'>
				<img src={parseRelativeUrl(logo)} alt='European Parliament Logo' style={{width: 300}}/>
				<Spin spinning={loading}>
					<Form
						name="normal_login"
						className="login-form"
						initialValues={{
							emember: true,
						}}
						onFinish={handleSubmit}
					>
						<Form.Item
							name="username"
						>
							<Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" onChange={handleUserChange}/>
						</Form.Item>
						<Form.Item
							name="password"
						>
							<Input.Password
								prefix={<LockOutlined className="site-form-item-icon" />}
								type="password"
								placeholder="Password"
								onChange={handlePasswordChange}
								iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
							/>
						</Form.Item>

						<Form.Item>
							{/* <Link to={ROUTES.FORGOT_PASSWORD} className="login-form-forgot">
								Forgot password
							</Link> */}
							<a
								href={getSetting('old_cms_url') + "/web/guest/login-editor?p_p_id=com_liferay_login_web_portlet_LoginPortlet&p_p_lifecycle=0&p_p_state=maximized&p_p_mode=view&_com_liferay_login_web_portlet_LoginPortlet_mvcRenderCommandName=%2Flogin%2Fforgot_password&saveLastPath=false"}
								className="login-form-forgot"
								target='blank'
							>
								Forgot password
							</a>
						</Form.Item>
					

						<Form.Item>
							<Button type="primary" htmlType="submit" className="login-form-button" style={{width: '100%' }}>
							Log in
							</Button>
						</Form.Item>
					</Form>
				</Spin>
				</Space>
			</Row>
		</>
	);
}
export default Login;