import { createAsyncThunk } from '@reduxjs/toolkit';
import { getData, deleteData } from '@/utils/api';
import { transformAudioListResults } from '@/utils/transformData/transformResults';

export const fetchEventList = createAsyncThunk(
	'eventList/eventListLoading',

	async ({pageSize = 20, page = 1, sort = 'eventDate:desc', searchType = 'epId', search = ''}, {rejectWithValue, getState}) => {
		const {auth: {jwttoken: auth}} = getState();

		const fetchData = async () => {
			try {
				const response = await getData({auth, endpoint: `/events?pageSize=${pageSize}&page=${page}&sort=${sort}&${searchType}=${search}`});

				if (response.error) {
					return rejectWithValue(response);
				}

				return transformAudioListResults(response.content);

			} catch (err) {
				rejectWithValue(err);
			}
		};

		return await fetchData();
	}
);

export const deleteItem = createAsyncThunk(
	'eventList/deleteItem',

	async ({epId}, {rejectWithValue, getState}) => {
		const {auth: {jwttoken: auth}} = getState();

		const fetchData = async () => {
			try {
				const response = await deleteData({auth, endpoint: `/event/${epId}`});

				if (response.error) {
					return rejectWithValue(response.message);
				}
				return transformAudioListResults(response.content);

			} catch (err) {
				rejectWithValue(err);
			}
		};

		return await fetchData();
	}
);