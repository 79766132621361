import { useSelector } from 'react-redux';

import { Col, Row, Space } from 'antd';

import mediaSettingsSelector from '@Redux/mediaSettings/mediaSettingsSelector';

import BasicSelect from '@Components/BasicComponents/BasicSelect';
import Validate from '@Components/Validate';

import { required } from '@/utils/errors/list';
import { localTimeFormated } from '@/utils/dates';

export default function ContentProperty ({selector, showOwner = true}) {

	const { owner, creationDate, modifiedDate, setValue } = selector();
	const { contentOwners } = useSelector(mediaSettingsSelector);

	const handleChange = (value) => setValue({param: 'owner', value});

	return (
		<>
			<h3>Content Property</h3>
			<Row justify={{xl: 'space-between', xxl: 'space-around'}} align="top">
				<>{showOwner &&
					<Col>
						<Space>
							<Validate requirements={[required]}>
								<BasicSelect
									title='Content owner'
									value={owner}
									onChange={handleChange}
									options={contentOwners.data}
								/>
							</Validate>
						</Space>
					</Col>
				}</>
				<Col>
					<Space>
						<Row align="middle" justify='space-between'>
							<Col span={10}>
								<label>Creation date:</label>
							</Col>
							<Col span={14} style={{ width: 200, padding: 8 }}>
								{localTimeFormated(creationDate)}
							</Col>
						</Row>
					</Space>
				</Col>
				<Col>
					<Space>
						<Row align="middle" justify='space-between'>
							<Col span={10}>
								<label>Updated date:</label>
							</Col>
							<Col span={14} style={{ width: 200, padding: 8 }}>
								{localTimeFormated(modifiedDate)}
							</Col>
						</Row>
					</Space>
				</Col>
			</Row>
			<br />
		</>
	);
}