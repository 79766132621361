import React from 'react';

import { Space, Typography } from 'antd';

const { Title } = Typography;

export default function Main () {

	return (
		<Space size="large">
			<Title>Welcome!</Title>
		</Space>
	);
}