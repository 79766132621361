import { useRef } from "react";
import { useBlocker, useNavigate } from "react-router-dom";

export default function useCustomPrompt(cb, shouldPrompt) {
  const navigate = useNavigate();
  const path = useRef('');

  const handleBlockNavigation = ({nextLocation, currentLocation}) => {
    if (nextLocation.pathname === currentLocation.pathname) {
      return false;
    }

    if (nextLocation.pathname === path.current) {
      return false;
    }

    if (shouldPrompt) {
      cb(() => {
        path.current = nextLocation.pathname;
        navigate(nextLocation.pathname);
      });
      return true;
    } else {
      return false;
    }
  };
 
  useBlocker(handleBlockNavigation);
}