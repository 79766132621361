import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Card, Col, Row, Space, Spin } from "antd";
import Compact from "antd/es/space/Compact";

import eventInfoSelector from '@Redux/eventInfo/eventInfoSelector';
import { getItemData } from '@Redux/eventInfo/eventInfoFetcher';
import mediaSettingsSelector from '@Redux/mediaSettings/mediaSettingsSelector';
import { setTags } from "@Redux/eventInfo/eventInfoSlice";

import useNotification from '@/hooks/notifications';

import MultiLanguageInput from '@Components/BasicComponents/MultiLanguageInput';
import BasicInput from '@Components/BasicComponents/BasicInput';
import TagList from '@Components/BasicComponents/TagList';

import { localTimeFormated } from '@/utils/dates';
import { useNavigate } from "react-router-dom";
import { getTags } from "@/utils/fetchFunctions/tags";



const neutralStyle = {
    color: 'black',
    backgroundColor: 'white'
};

const buttoSstyle = {
		width: 200,
		padding: '5px 10px',
		borderRadius: '100px'
	};


export default function EventInfo ({epId}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { process: {succeed, loading, error}, mediaType, category, title, description, tags, location, seoTitle, seoDescription, eventDate, eventEndDate, startPublicationDate, endPublicationDate, links, owner, modifiedDate, creationDate, coverage} = useSelector(eventInfoSelector());
    const { mediaTypes, coverages } = useSelector(mediaSettingsSelector);

    const {openNotification} = useNotification();

	const [countryCode, setCountryCode] = useState('en_GB');

    const type = useMemo(() => mediaTypes.data.filter(({value})=> value === mediaType)[0]?.label, [mediaType, mediaTypes]);
    const mediaCategory = useMemo(() => mediaTypes.categories[mediaType]?.filter(({value})=> value === category)[0]?.label, [category, mediaType, mediaTypes]);

    const labelCoverage = useMemo(() => coverage?.map((value)=> coverages.data[value]), [coverages.data, coverage]);

    useEffect(() => {
		if(epId) {
			dispatch(getItemData({epId}));
		}
	}, [epId]);

	useEffect(()=> {
		if (error?.error) {
			openNotification({message: error.status, description: error.title, type: 'error'});
		}
	}, [error]);

    useEffect(() => {
		if (succeed) {
            fetchTags(tags);
		}
	}, [succeed]);

    async function fetchTags(tags) {
        const newTags = await getTags(tags);
        dispatch(setTags(newTags));
    }

    function goBack () {
		navigate(-1);
	}

    return<Spin spinning={loading}>
        <Compact block size="middle" direction='vertical'>
            <br /><br />
            <Alert
                message="Event information cannot be modified from the Multimedia Centre CMS."
                description="Please contact Audiovisual Unit to edit or modify any of the metadata you can find on this tab."
                type="info"
                showIcon
            />
            <br /><br />
            <>
                <h3>Content Property</h3>
                <Row justify={{xl: 'space-between', xxl: 'space-around'}} align="top">
                    <Col>
                        <Space>
                            <BasicInput
                                title='Media Type'
                                value={type}
                                style={neutralStyle}
                                disabled
                                inline
                            />
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <BasicInput
                                title='Category'
                                value={mediaCategory}
                                style={neutralStyle}
                                disabled
                                inline
                            />
                        </Space>
                        
                    </Col>
                    <Col>
                        <Space>
                            <BasicInput
                                title='Reference ID'
                                value={epId}
                                style={neutralStyle}
                                disabled
                                inline
                            />
                        </Space>
                    </Col>
                </Row>
            </>
            <br /><br /><br />
            <>
                <h3>Main Information</h3>
                <MultiLanguageInput
                    title="Title"
                    value={title}
                    style={{marginVertical: 10, ...neutralStyle}}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                    disabled
                />
                <br />
                <MultiLanguageInput
                    title="Description"
                    textArea
                    style={neutralStyle}
                    value={description}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                    disabled
                />

                <br />

                <TagList
                    title="Tags"
                    value={tags}
                    disabled
                />
                <br />
                <TagList
                    title="Coverage"
                    value={labelCoverage}
                    disabled
                />
                <br />
                <BasicInput
                    title="Location"
                    value={location}
                    style={neutralStyle}
                    disabled
                />
            </>
            <br /><br /><br />
            <>
                <Row justify="space-between" align="middle">
                    <h3>SEO Information</h3>
                </Row>
                <MultiLanguageInput
                    title="SEO title (max. 60 characters)"
                    value={seoTitle}
                    style={{marginVertical: 10, ...neutralStyle}}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                    disabled
                />
                <br />
                <MultiLanguageInput
                    title="SEO description (max. 160 characters)"
                    textArea
                    value={seoDescription}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                    style={neutralStyle}
                    disabled
                />
            </>
            <br /><br /><br />
            <>
                <h3>Publishing Dates</h3>
                <Row gutter={[16, 24]} justify="start">
                    <Col className="gutter-row" md={24} lg={12}>
                        <BasicInput
                            title="Event start date"
                            value={localTimeFormated(eventDate, true)}
                            inline
                            style={neutralStyle}
                            disabled
                        />
                    </Col>
                    <Col className="gutter-row" md={24} lg={12}>
                        <BasicInput
                            title="Event end date"
                            value={localTimeFormated(eventEndDate, true)}
                            style={neutralStyle}
                            disabled
                            inline
                        />
                        
                    </Col>
                    <Col className="gutter-row" md={24} lg={12}>
                        <BasicInput
                            title="Publication start date"
                            value={localTimeFormated(startPublicationDate, true)}
                            style={neutralStyle}
                            disabled
                            inline
                        />
                        
                    </Col>
                    <Col className="gutter-row" md={24} lg={12}>
                        <BasicInput
                            title="Publication end date"
                            value={localTimeFormated(endPublicationDate, true)}
                            style={neutralStyle}
                            disabled
                            inline
                        />
                        
                    </Col>
                    
                </Row>
            </>
            <br /><br /><br />
            <>
                <h3>Links and attachments</h3>
                {	links.length > 0 ?
                    links.map(({ref, description, id}) =>
                        <Card 
                            key={id}
                            style={{ marginTop: 16 }}
                        >
                            <MultiLanguageInput
                                title="Link"
                                value={ref}
                                style={{marginVertical: 10, ...neutralStyle}}
                                countryCode={countryCode}
                                setCountryCode={setCountryCode}
                                disabled
                            />
                            <br/>
                            <MultiLanguageInput
                                title="Descriptive text"
                                value={description}
                                style={{marginVertical: 10, ...neutralStyle}}
                                countryCode={countryCode}
                                setCountryCode={setCountryCode}
                                disabled
                            />
                        </Card>
                    ) : 
                    <Card
                        style={{ marginTop: 16 }}
                    >
                        This media has no links.
                    </Card>
                }
            </>
            <br /><br /><br />
            <>
                <h3>Content Property</h3>
                <Row justify={{xl: 'space-between', xxl: 'space-around'}} align="top">
                    <Col>
                        <Space>
                            <BasicInput
                                title='Content owner'
                                value={owner}
                                style={neutralStyle}
                                disabled
                                inline
                            />
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <BasicInput
                                title='Creation date'
                                value={localTimeFormated(creationDate)}
                                style={neutralStyle}
                                disabled
                                inline
                            />
                        </Space>
                        
                    </Col>
                    <Col>
                        <Space>
                            <BasicInput
                                title='Updated date'
                                value={localTimeFormated(modifiedDate)}
                                style={neutralStyle}
                                disabled
                                inline
                            />
                        </Space>
                    </Col>
                </Row>
            </>
            <br /><br /><br />
            <Row justify='center'>
                <Button onClick={goBack} type="primary" size='large' style={buttoSstyle}>GO BACK</Button>
            </Row>
        </Compact>
    </Spin>;

}