import sanitizeTitle from "./sanitizeTitle";

function convertFilename(filename){
    return sanitizeTitle(filename);
} 

var currentConections = {};
let handlerIdList = [];
let idListPosition = 0;

function IbmS3Uploader(options) {
    const maxRetries = options.maxRetries || 3;
    const timeRetries = options.timeRetries || 2000;

    const s3tokenApi = options.s3tokenApi || '';

    const endpoint = options.endpoint || '';
    const bucketName = options.bucketName || '';
    const authToken = options.authToken || '';
    const chunkSize = options.sliceSize || 10 * 1000 * 1024; // 10 MB chunk size
    const maxConections = options.maxConections || 6;
    var bucketPrefix = options.bucketPrefix || '';
    
    var ibmToken = '';

 
    var uploaderHandler = {};

    function uniqueId() {
        return '' + Date.now() + '_' + Math.floor(Math.random() * 100);
    }

    function setBucketPrefix(prefix){
        bucketPrefix = prefix;
    }

    function addFile(file, callback) {
        var id = uniqueId();
        var numberofChunks = Math.ceil(file.size / chunkSize);
        var filename = bucketPrefix + convertFilename(file.name);


        uploaderHandler[id] = {
            file: file,
            parts: [],
            currentChunk: 0,
            totalChunks : numberofChunks,
            location: '',
            uploadId: null,
            objectName: filename,
            progress: 0,
            callback: callback || function () { },
            status: 0, // pending: 0 || processing :1 || complete: 2 || error : -1 ,
            timeStart: 0,
            timeEnd: 0
        };

        handlerIdList.push(id);

        return id;
    }

    function getNextChunk(handlerId) {
        const handler = uploaderHandler[handlerId];
        if(handler.currentChunk == handler.totalChunks) return null;
        let num = ++handler.currentChunk;
        let start = (handler.currentChunk - 1) * chunkSize;
        let part = {
            start,
            end: Math.min(start + chunkSize, handler.file.size),
            num,
            status:0,
            etag: ''
        };
        handler.parts.push(part);
        return part;

    }

    function remove(handlerId) {
        if(typeof uploaderHandler[handlerId] === 'undefined') return null;
        uploaderHandler[handlerId].callback = () => {};
        if (uploaderHandler[handlerId].status == 0) {
            delete uploaderHandler[handlerId];
        }else if(uploaderHandler[handlerId].status == 2){
            deleteByObjectName(uploaderHandler[handlerId].objectName);
        } else {
            uploaderHandler[handlerId].status = -1;
        }

        if(currentConections[handlerId] == 0){
            handlerTick(handlerId);
        }
        
        return true;
    }


    function run() {
        getS3tokenIbm (() => {
            uploadNextObject();
        });    
    }

    function uploadNextObject () {
        let handlerId = null;

        if (handlerIdList.length > idListPosition) {
            handlerId = handlerIdList[idListPosition];
            idListPosition++;
        }
        
        if(handlerId && uploaderHandler[handlerId].status === 0 ) {
            InitiateMultipartUpload(handlerId);
        }  
    }


    function getS3tokenIbm(callback){
        callback = callback || function () {};
        fetch(s3tokenApi, {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
				'Accept-Language': 'en-GB',
                'X-API-KEY': (typeof authToken === 'function')? authToken() : authToken
            }
        })
            .then(response => response.json())
            .then(data => {
                ibmToken = 'Bearer ' + data.content.s3token;
                callback();
                
            })
            .catch(err => { console.error(' ibmToken error', err); });
    }

    function handlerTick(handlerId) {
        // es importante comprobar los pendientes al principio porque podrian finalizar durante las comprobaciones.
        const hasProccessingParts = currentConections[handlerId] > 0;

        if (uploaderHandler[handlerId].status == -1) { 
            //si todavia hay partes subiendo ... esperamos sus ticks sino abortamos
            if (!hasProccessingParts) abortUpload(handlerId);
            return;
        }

        if(currentConections[handlerId] >=  maxConections)  {
            //demasiadas conexiones esperamos
            return;
        }

        var nextPart = getNextChunk(handlerId);

        //console.log('>>>',nextPart);

        if (!nextPart  &&  !hasProccessingParts ) {
            //si no hay nuevas partes y no hay ninguno en progreso completmos, sino esperamos
            completeUpload(handlerId);
            uploadNextObject();
            return;
        }

        if (nextPart) {
            uploadPart(handlerId, nextPart);
            handlerTick(handlerId);
        }

    }

    function InitiateMultipartUpload(handlerId) {
        currentConections[handlerId] = 0;
        uploaderHandler[handlerId].timeStart = (new Date()).getUTCSeconds();
        const objectName = uploaderHandler[handlerId].objectName;
        const url = `https://${endpoint}/${bucketName}/${objectName}?uploads=`;

        fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                Authorization: ibmToken,
                'Content-Type': uploaderHandler[handlerId].file.type
            },
            body: ''
        }).then(response => response.text())
            .then(xmlString => (new DOMParser()).parseFromString(xmlString, 'text/xml'))
            .then(xmlDoc => {

                let uploadId = xmlDoc.getElementsByTagName('UploadId')[0].innerHTML;
                uploaderHandler[handlerId].uploadId = uploadId;
                uploaderHandler[handlerId].status = 1;

                sendMessage(handlerId, 'INIT', { uploadId, handlerId });


            })
            .catch(error => {
                uploaderHandler[handlerId].status = -1;
                sendMessage(handlerId, 'ERROR', { msg: `failed to initialize ${handlerId} `, error, url, authToken });
            })
            .finally(() => {
                handlerTick(handlerId);
            });
    }

    function uploadPart(handlerId, part, attempt = 0) {
        if (uploaderHandler[handlerId].status == -1) return;

        ++currentConections[handlerId] || (currentConections[handlerId] = 1);
        const chunk = uploaderHandler[handlerId].file.slice(part.start, part.end);
        const sequentialInteger = part.num;
        const objectName = uploaderHandler[handlerId].objectName;
        const uploadId = uploaderHandler[handlerId].uploadId;
        const url = `https://${endpoint}/${bucketName}/${objectName}?partNumber=${sequentialInteger}&uploadId=${uploadId}`;

        const partIndex = uploaderHandler[handlerId].parts.findIndex(element => element.num == sequentialInteger);

        uploaderHandler[handlerId].parts[partIndex].status = 1; //processing

        fetch(url, {
            method: 'PUT',
            cache: 'no-cache',
            headers: {
                Authorization: ibmToken,
                'Content-Type': uploaderHandler[handlerId].file.type
            },
            body: chunk
        }).then(response => {
            const etagHeader = [...response.headers].filter(header => header[0].toLowerCase() == 'etag');

            if (etagHeader.length != 1) { throw new Error("Error on get etag!"); }

            uploaderHandler[handlerId].parts[partIndex].etag = etagHeader[0][1];
            uploaderHandler[handlerId].parts[partIndex].status = 2;

            if (uploaderHandler[handlerId].status != -1) {
                sendMessage(handlerId, 'PERCENT', { handlerId, percent: getPercentComplete(handlerId) });
            }

        }).catch(() => {
            attempt++;
            if (attempt <= maxRetries) {
                setTimeout(() => { uploadPart(handlerId, part, attempt); }, timeRetries);
                return;
            }

            uploaderHandler[handlerId].parts[partIndex].status = -1;
            uploaderHandler[handlerId].status = -1;
            sendMessage(handlerId, 'ERROR', { msg: `error on ${uploaderHandler[handlerId].name} : part ${part.num} ` });

        }).finally(() => {

            currentConections[handlerId]--;
            handlerTick(handlerId);
        });
    }

    function completeUpload(handlerId) {
        const objectName = uploaderHandler[handlerId].objectName;
        const uploadId = uploaderHandler[handlerId].uploadId;
        const url = `https://${endpoint}/${bucketName}/${objectName}?uploadId=${uploadId}`;

        let partsRequest = '';
        for (let part of uploaderHandler[handlerId].parts) {
            partsRequest += `<Part><PartNumber>${part.num}</PartNumber><ETag>${part.etag}</ETag></Part>`;
        }


        fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                Authorization: ibmToken,
                'Content-Type': uploaderHandler[handlerId].file.type
            },
            body: `<CompleteMultipartUpload>${partsRequest}</CompleteMultipartUpload>`
        }).then(response => response.text())
            .then(xmlString => (new DOMParser()).parseFromString(xmlString, 'text/xml'))
            .then(xmlDoc => {
                let location = xmlDoc.getElementsByTagName('Location')[0].innerHTML;
                location = location.replace('http://','https://');
                uploaderHandler[handlerId].location = location;
                uploaderHandler[handlerId].status = 2;

                uploaderHandler[handlerId].timeEnd = (new Date()).getUTCSeconds();
                sendMessage(handlerId, 'COMPLETED', { handlerId, location, time: uploaderHandler[handlerId].timeEnd -  uploaderHandler[handlerId].timeStart });
            })
            .catch(error => {
                uploaderHandler[handlerId].status = -1;
                abortUpload(handlerId);
                sendMessage(handlerId, 'ERROR', { handlerId, location, error });
            });

    }

    // delete all unfinished parts of a multipart upload.
    function abortUpload(handlerId) {
        const objectName = uploaderHandler[handlerId].objectName;
        const uploadId = uploaderHandler[handlerId].uploadId;
        const url = `https://${endpoint}/${bucketName}/${objectName}?uploadId=${uploadId}`;

        fetch(url, {
            method: 'DELETE',
            cache: 'no-cache',
            headers: {
                Authorization: ibmToken,
                'Content-Type': uploaderHandler[handlerId].file.type
            },
            body: ''
        }).then(() => { });
    }

    //deletes an object. 
    function deleteByObjectName (objectName) {
        getS3tokenIbm (() => {
            const url = `https://${endpoint}/${bucketName}/${objectName}`;
            fetch(url, {
                method: 'DELETE',
                cache: 'no-cache',
                headers: {
                    Authorization: ibmToken
                },
                body: ''
            }).then(() => { });
        });
    }

    function getPercentComplete(handlerId) {
        let total = uploaderHandler[handlerId].parts.length;
        let complete = 0;

        for (let part of uploaderHandler[handlerId].parts) {
            if (part.status == 2) complete++;
        }

        return Math.floor(complete * 100 / total);
    }


    function sendMessage(handlerId, type, payload) {
        uploaderHandler[handlerId].callback({
            type: type,
            data: payload
        });
    }

    return { addFile, run, remove, uploaderHandler, ibmToken, deleteByObjectName , setBucketPrefix };

}

export default  IbmS3Uploader;