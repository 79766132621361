import { createBrowserRouter, } from 'react-router-dom';

import * as ROUTES from './routes';
import Home from '@Pages/Home';
import ErrorPage from '@Pages/Error';
import MediaList from '@Pages/Media/List';
import Login from '@Pages/Login';
import Upload from '@Pages/Media/Upload';
import Edit from '@Pages/Media/Edit';
import ForgotPassword from '@Pages/ForgotPassword';
import Main from '@Pages/Main';
import EventList from '@Pages/Event/List';
import EventDetails from '@Pages/Event/Details';
import UnderConstruction from '@Pages/UnderConstruction';
import Elections from '@Pages/Elections';
import MonitoringDashboards from '@Pages/MonitoringDashboards';

import { GoBackContextProvider as GoBack } from '@/context/goBackContext';
import Topics from '@Pages/Topics';
import CreateTopic from '@Pages/Topics/Create';
import EditTopic from '../pages/Topics/Edit';

export default createBrowserRouter([
	{
		path: ROUTES.HOME,
		element: <Main />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: ROUTES.HOME,
				element: <Home />,
			},
			{
				path: ROUTES.MEDIA,
				element: <MediaList />,
			},
			{
				path: ROUTES.UPLOAD_MEDIA,
				element: <Upload />,
			},
			{
				path: `${ROUTES.EDIT_MEDIA}/:type/:epId`,
				element: <Edit />,
			},
			{
				path: ROUTES.RELEVANT_MEDIA,
				element: <UnderConstruction />,
			},
			{
				path: ROUTES.EVENTS,
				element: <EventList />,
			},
			{
				path: `${ROUTES.EVENT_DETAILS}/:epId`,
				element: <GoBack><EventDetails /></GoBack>,
			},
			{
				path: ROUTES.TOPICS,
				children: [
					{
						path: ROUTES.LIST_TOPIC,
						element: <Topics />,
					},
					{
						path: ROUTES.CREATE_TOPIC,
						element: <CreateTopic />,
					},
					{
						path: `${ROUTES.EDIT_TOPIC}/:referenceId`,
						element: <GoBack><EditTopic /></GoBack>,
					},
					{
						path: ROUTES.SPOTLIGHTED_TOPICS,
						element: <UnderConstruction />,
					},
					{
						path: ROUTES.FEATURED_TOPICS,
						element: <UnderConstruction />,
					},
					{
						path: ROUTES.CONTENT_TOPICS,
						element: <UnderConstruction />,
					},
					{
						path: ROUTES.TRENDING_TOPICS,
						element: <UnderConstruction />,
					},
				]
			},
			{
				path: ROUTES.TAGS,
				element: <UnderConstruction />,
			},
			{
				path: ROUTES.OTHERS,
				element: <UnderConstruction />,
				children: [
					{
						path: ROUTES.HOMEPAGE_MEDIA_CARDS,
						element: <UnderConstruction />,
					},
					{
						path: ROUTES.HOMEPAGE_LIVE_STREAMING,
						element: <UnderConstruction />,
					},
					{
						path: ROUTES.SERVICE_MESSAGE,
						element: <UnderConstruction />,
					},
					{
						path: ROUTES.PROMOTIONAL_BANNER,
						element: <UnderConstruction />,
					},
				]
			},
			{
				path: ROUTES.DASHBOARDS,
				children: [
					{
						path: ROUTES.MONITORING_DASHBOARDS,
						element: <MonitoringDashboards />,
					},
					{
						path: ROUTES.DASHBOARD_FILE_PROCESSING,
						element: <UnderConstruction />,
					},
					{
						path: ROUTES.DASHBOARD_MOST_VISITED,
						element: <UnderConstruction />,
					},
					{
						path: ROUTES.DASHBOARD_MOST_DOWNLOADED,
						element: <UnderConstruction />,
					},
				]
			},
			{
				path: ROUTES.USERS,
				element: <UnderConstruction />,
			},
			{
				path: ROUTES.TASKS,
				element: <UnderConstruction />,
			},
			{
				children: [
					{
						path: ROUTES.SYNONYMS,
						element: <UnderConstruction />,
					},
					{
						path: ROUTES.HYPONYMS,
						element: <UnderConstruction />,
					},
					{
						path: ROUTES.ACRONYMS,
						element: <UnderConstruction />,
					},
					{
						path: ROUTES.KEYWORDS_EXPANSION,
						element: <UnderConstruction />,
					},
					{
						path: ROUTES.ALIASES,
						element: <UnderConstruction />,
					},
					{
						path: ROUTES.AUTOCORRECTION_WORDS,
						element: <UnderConstruction />,
					},
				]
			},
			{
				path: ROUTES.ELECTIONS_PAGE,
				element: <GoBack><Elections /></GoBack>,
			},
		],
	},
	{
		path: ROUTES.LOGIN,
		element: <Login />,
	},
	{
		path: ROUTES.FORGOT_PASSWORD,
		element: <ForgotPassword />,
	},
]);