import { createSlice } from '@reduxjs/toolkit';
import { fetchTagList, fetchLanguages, fetchOwners, fetchMediaTypes, fetchCoverages } from './mediaSettingsFetchers';

export const mediaSettingsSlice = createSlice({
	name: 'mediaSettings',
	initialState: {
		languages: {
			loading: false,
			data: [],
			error: {}
		},
		mediaTypes: {
			loading: false,
			data: [],
			categories: {},
			error: {}
		},
		tagList: {
			loading: false,
			data: [],
			error: {}
		}, 
		contentOwners: {
			loading: false,
			data: [],
			error: {}
		},
		coverages: {
			loading: false,
			data: [],
			error: {}
		},
	},
	reducers: {
		setTaglist: (state, action) => {
			return {
				...state,
				tagList: {
          loading: false,
          data: action.payload,
          error: {}
        }
			};
		}
	},
	extraReducers: {
		// Taglist
		[fetchTagList.pending.type]: (state) => {
			state.tagList = {
				loading: true,
				data: state.tagList.data,
				error: {}
			};
		},
		[fetchTagList.fulfilled.type]: (state, action) => {
			const res = action.payload?.length ? action.payload : [];
			state.tagList = {
				loading: false,
				data: res,
				error: {}
			};
		},
		[fetchTagList.rejected.type]: (state, action) => {
			state.tagList = {
				loading: false,
				data: [],
				error: action.payload
			};
		},
		// Languages
		[fetchLanguages.pending.type]: (state) => {
			state.languages = {
				loading: true,
				data: state.languages.data,
				error: {}
			};
		},
		[fetchLanguages.fulfilled.type]: (state, action) => {
			state.languages = {
				loading: false,
				data: action.payload?.optionsLists,
				error: {}
			};
		},
		[fetchLanguages.rejected.type]: (state, action) => {
			state.languages = {
				loading: false,
				data: [],
				error: action.payload
			};
		},
		// Owners
		[fetchOwners.pending.type]: (state) => {
			state.contentOwners = {
				loading: true,
				data: state.contentOwners.data,
				error: {}
			};
		},
		[fetchOwners.fulfilled.type]: (state, action) => {
			state.contentOwners = {
				loading: false,
				data: action.payload,
				error: {}
			};
		},
		[fetchOwners.rejected.type]: (state, action) => {
			state.contentOwners = {
				loading: false,
				data: [],
				error: action.payload
			};
		},
		// MediaTypes
		[fetchMediaTypes.pending.type]: (state) => {
			state.mediaTypes = {
				loading: true,
				data: state.mediaTypes.data,
				categories: state.mediaTypes.categories,
				error: {}
			};
		},
		[fetchMediaTypes.fulfilled.type]: (state, action) => {
			state.mediaTypes = {
				loading: false,
				data: action.payload?.types || [],
				categories: action.payload?.categories,
				error: {}
			};
		},
		[fetchMediaTypes.rejected.type]: (state, action) => {
			state.mediaTypes = {
				loading: false,
				data: [],
				categories: {},
				error: action.payload
			};
		},
		// Coverages
		[fetchCoverages.pending.type]: (state) => {
			state.coverages = {
				loading: true,
				data: state.coverages.data,
				error: {}
			};
		},
		[fetchCoverages.fulfilled.type]: (state, action) => {
			state.coverages = {
				loading: false,
				data: action.payload,
				error: {}
			};
		},
		[fetchCoverages.rejected.type]: (state, action) => {
			state.coverages = {
				loading: false,
				data: [],
				error: action.payload
			};
		}
	}
});

export const { setMediaType, setCategory, setTaglist } = mediaSettingsSlice.actions;

export default mediaSettingsSlice.reducer;