import { useContext, useEffect } from "react";

import TopicForm from "../Forms/TopicForm";
import { TopicContext } from "../../context/topicContext/topicContext";


export default function TopicInfo ({referenceId}) {
    const { getTopicData } = useContext(TopicContext);
    useEffect(() => {
		if(referenceId) {
			getTopicData(referenceId);
		}
	}, [referenceId]);

    return <TopicForm edit />;

}