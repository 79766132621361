import { Row } from 'antd';

import BasicInput from '@Components/BasicComponents/BasicInput';
import Validate from '@Components/Validate';
import MultiLanguageInput from '@Components/BasicComponents/MultiLanguageInput';

import { isYear } from '@/utils/errors/list';


export default function CopyrightInformation ({selector}) {
	const {
		copyrightAuthor,
		termsOfUse,
		copyrightYear,
		copyrightLicense,
		countryCode,
		setCountryCode,
		setValue
	} = selector();


	const handleChange = (param) => {
		return ({value, countryCode}) =>{
			setValue({param, countryCode, value});
		};
	};

	const handleYear = (value) => {
		setValue({param: 'copyrightYear', value});
	};

	return (
		<>
			<Row>
				<h3>Copyright information</h3>
			</Row>
			<br/>
				<MultiLanguageInput
					title="Copyright Owner"
					value={copyrightAuthor}
					onChange={handleChange('copyrightAuthor')}
					style={{marginVertical: 10}}
					countryCode={countryCode}
					setCountryCode={setCountryCode}
				/>
			<br/>
				<MultiLanguageInput
					title="Usage Terms"
					value={termsOfUse}
					onChange={handleChange('termsOfUse')}
					style={{marginVertical: 10}}
					countryCode={countryCode}
					setCountryCode={setCountryCode}
				/>
			<br/>
				<MultiLanguageInput
					title="Copyright License"
					value={copyrightLicense}
					onChange={handleChange('copyrightLicense')}
					style={{marginVertical: 10}}
					countryCode={countryCode}
					setCountryCode={setCountryCode}
				/>
			<br/>
			<Validate requirements={[isYear]}>
				<BasicInput
					title="Copyright Year"
					value={copyrightYear}
					onChange={handleYear}
				/>
			</Validate>
		</>
	);
}