import { Col, Input, Row, Space, Spin, Typography } from 'antd';
import Compact from 'antd/es/space/Compact';
const {Text} = Typography;

export default function BasicInput ({title, value, onChange, error = {isOk: true}, inline = false, loading = false, ...rest}) {

	function handleChange(e) {
		onChange(e.target.value);
	}

	if (inline) {
		return (
			<>
				<Row align="middle">
					<Col span={8}>
						<label>{title}</label>
					</Col>
					<Col span={16}>
						<Spin spinning={loading}>
							<Input
								value={value}
								onChange={handleChange}
								style={{ width: 200 }}
								status={error?.show && error.type}
								{...rest}
							/>
						</Spin>
					</Col>
				</Row><Row align="middle">
					<Col offset={8} span={16}>
						<Text type={error.type === 'error' ? 'danger' : 'warning'}>{error?.text}</Text>
					</Col>
				</Row>
			</>
			
		);
	}
	return (
		<Compact block size="middle" direction='vertical'>
			<label>{title}</label>
			<br/>
			<Space size="middle" direction='vertical' style={{width: '100%'}}>
				<Input
					title={title}
					value={value}
					onChange={handleChange}
					status={error?.show && error.type}
					{...rest}
				/>
			</Space>
			<Text type={error.type === 'error' ? 'danger' : 'warning'}>{error?.text}</Text>
		</Compact>
		
		
	);
}