import { useState } from 'react';
import MainInformation from '@Components/ComposedComponents/MainInformation';
import SEOInformation from '@Components/ComposedComponents/SEOInformation';

export default function AudioForm () {
	const [countryCode, setCountryCode] = useState('en_GB');

	const props = {countryCode, setCountryCode};

	return (
		<>
			<p>video</p>
			<MainInformation {...props}/>
			<br/><br/>
			<SEOInformation {...props}/>
		</>
		
	);
}