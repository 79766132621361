import { useEffect, useMemo } from 'react';

import { Input, Radio, Select, Space } from 'antd';
const { Search } = Input;

import HighlightedTable from '@Components/Tables/HighlightedTable';

import useNotification from '@/hooks/notifications';
import useFetchData from '@/hooks/useFetchData';
import { highlightItem } from '@/utils/fetchFunctions/highlight';


const types = [
	{ label: 'VIDEOS', value: 'EPV_VIDEO_FOOTAGE%3BEPV_EDITED_VIDEOS' },
	{ label: 'PHOTOSETS', value: 'EPV_PHOTOSET' },
];

const searchTypes = [
	{ label: 'Search by reference ID', value: 'epId' },
	{ label: 'Search by keywords', value: 'search' },
];

export default function HighlightedMediasTable({url, selected = false, showSearch = true}) {
	const {openNotification} = useNotification();

	const associatedMediasParams =  { url, category: 'EPV_VIDEO_FOOTAGE%3BEPV_EDITED_VIDEOS', automatic: false };
	const {setParam, setParams, searchParams, data, loading, error} = useFetchData({params: associatedMediasParams, usePaginaton: false});

	const highlightedMediaParams = { url, category: 'EPV_VIDEO_FOOTAGE%3BEPV_EDITED_VIDEOS', highlighted: true };
	const highlightedMedia = useFetchData({params: highlightedMediaParams, usePagination: false});

	const highlightedList = useMemo(() => highlightedMedia?.data?.map( media => {
			return {...media, selected: true};
	}), [highlightedMedia.data]);

	const displayData = useMemo(() => {
		if (selected) {
			return highlightedList;
		}

		return data?.map( media => {
			const index = highlightedList?.findIndex( a => {
				return a.mediaId === media.mediaId;
			});

			return {...media, selected: index !== -1};
		});
	}, [selected, data, highlightedList]);

	useEffect(() => {
		setParams({sort: 'mediaDate:desc'});
	}, []);

	useEffect(() => {
		if (error) {
			openNotification({message: 'Something went wrong!', description: error.message || null, type: 'error'});
		}
	}, [error]);

	function getCategory() {
		return searchParams.get("category") || 'EPV_VIDEO_FOOTAGE%3BEPV_EDITED_VIDEOS';
	}

	const handleType = ({ target: { value } }) => {
		setParams({category: value});
	};

	const handleSearch = () => {
		setParams({sort: 'relevancy:asc'});
	};

	const getListData = async () => {
		highlightedMedia.getData();
	};

	const select = async (id) => {
		const res = await highlightItem({url, id});

		if (res.error) {
			openNotification({message: 'Something went wrong!', description: res.message || null, type: 'error'});
			return;
		}

		await getListData();
	};

	const unselect = async (id) => {
		const res = await highlightItem({url, id, highlighted: false});

		if (res.error) {
			openNotification({message: 'Something went wrong!', description: res.message || null, type: 'error'});
			return;
		}

		await getListData();
	};

	return (
		<Space size="large" direction='vertical' style={{width: '100%'}} >
			<Radio.Group
				options={types}
				onChange={handleType}
				value={getCategory()}
				optionType="button"
				size="large"
			/>
			{(!selected && showSearch) && <Search
				addonBefore={
					<Select
						style={{width: 190}}
						options={searchTypes}
						value={searchParams.get("searchType") || 'search'}
						onChange={setParam('searchType')}
					/>
				}
				value={searchParams.get("search") || ''}
				onChange={setParam('search', false)}
				onSearch={handleSearch}
				enterButton
			/>}
			<>
				<HighlightedTable
					data={displayData}
					loading={loading}
					sort={searchParams.get("sort") || 'mediaDate:desc'}
					setSort={setParam('sort')}
					getListData={getListData}
					type={getCategory()}
					name="medias"
					select={select}
					unselect={unselect}
				/>
			</>
		</Space>
	);
}