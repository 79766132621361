const authCookieName = '_auth_';
const refreshTimers = {};
var tokensCallback = () => {};
import {  getData } from '@/utils/api';

function UTC() {
    return Math.ceil((new Date()).getTime() / 1000);
}


function setCookie(name, value, seconsToexpire, domain, path) {
    var e = name;
    var t = value;
    var i = seconsToexpire || 24 * 3600;
    var o = new Date;
    path = path || '/';
    domain = domain || document.location.hostname;
    if (i) o.setTime(o.getTime() + i * 1000);
    var a = o.toGMTString();
    document.cookie = e + " = " + encodeURI(t) + (null == i ? "" : "; expires = " + a) + ";domain=" + domain + ";path=" + path;
}


function getCookie(e) {
    var t, o, a = null,
        n = document.cookie.split(";");
    for (let i = 0; i < n.length; i++) o = n[i].slice(n[i].indexOf("=") + 1), t = n[i].slice(0, n[i].indexOf("=")).replace(/^\s+|\s+$/g, ""), t == e && (a = decodeURI(o));
    return a;
}

function decodeJWT(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}


function getSavedTokens() {
    try {
        let saved = getCookie(authCookieName);
        if (!saved) throw "empty";
        saved = JSON.parse(saved);
        return saved;

    } catch (e) {
        deleteTokens();
        return null;
    }
}

export async function refreshToken(tokens){
    try {
        const response = await getData({auth:tokens.refreshToken, endpoint: `/refresh`});
        if(!response.content) throw "no tokens";
        saveTokens(response.content);
    }catch(e){
        deleteTokens();
    }
}

export function saveTokens({tokens}){
    if (refreshTimers[authCookieName]) {
        clearTimeout(refreshTimers[authCookieName]);
    }
    let  secondsToExpire = parseInt(decodeJWT(tokens.jwttoken).exp) - UTC();
    setCookie(authCookieName, JSON.stringify(tokens), secondsToExpire);

    refreshTimers[authCookieName] = setTimeout(() => {
        refreshToken(tokens);
    }, (secondsToExpire * 1000));

    tokensCallback(tokens);
}


export function deleteTokens(){
    if (refreshTimers[authCookieName]) {
        clearTimeout(refreshTimers[authCookieName]);
    }
    setCookie(authCookieName, '', - 3600);
}

export function setTokensCallback (callback){
    if(typeof callback !== 'function')  callback = () => {};
    tokensCallback = callback;
}

export function  checkAuthTokens() {

    try {
        var tokens = getSavedTokens();
        let  secondsToExpire = parseInt(decodeJWT(tokens.jwttoken).exp) - UTC();
        if(secondsToExpire <= 0 ) throw "token expired";

        saveTokens({tokens,secondsToExpire});
        return tokens;

    } catch (e) {
        deleteTokens();
    }
}

export function tokenTimeLeft() {
    var tokens = getSavedTokens();
    if (!tokens?.jwttoken) return -1;
    let  secondsToExpire = parseInt(decodeJWT(tokens.jwttoken).exp) - UTC();
    return secondsToExpire;
}


