import { createAsyncThunk } from '@reduxjs/toolkit';
import {saveTokens,deleteTokens} from '@/utils/sessions';
import { postData, getData } from '@/utils/api';


async function AuthTokens(data ){
    try {
        const response = await postData({ data, endpoint: '/login' });
        const tokens = response.content;

        if(!tokens) throw "not tokens";
        saveTokens({tokens});
        return tokens;

    } catch (e) {
        deleteTokens();
    }
}

export const login = createAsyncThunk(
	'auth/authLoading',

	async (data, {rejectWithValue}) => {
		const fetchData = async () => {
					try {
				const tokens = await AuthTokens(data);
				if (!tokens) {
					return rejectWithValue({title: 'Unauthorized',status:401});
				}
				return tokens;

			} catch (err) {
				rejectWithValue(err);
			}
		};

		return await fetchData();
	}
);

export const refreshSession = createAsyncThunk(
	'auth/refreshSession',

	async (_, {rejectWithValue, getState}) => {
		const {auth: {refreshToken}} = getState();
		const fetchData = async () => {
					try {
				const response = await getData({auth: refreshToken, endpoint: `/refresh`});
				const tokens = response.content;

				if (!tokens) {
					return rejectWithValue({title: 'Unauthorized',status:401});
				}

				saveTokens({tokens});

				return tokens;

			} catch (err) {
				deleteTokens();
				rejectWithValue(err);
			}
		};

		return await fetchData();
	}
);
