import { useCallback, useMemo,useRef,useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Button, Card, Row, Select, Space } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
const { Option } = Select;

import mediaSettingsSelector from '@Redux/mediaSettings/mediaSettingsSelector';

import Validate from '@Components/Validate';
import DragUpload from '@Components/BasicComponents/DragUpload';
import MultiLanguageInput from '@Components/BasicComponents/MultiLanguageInput';

import useIbmS3Uploader from '@/hooks/useIbmS3UploaderNew';

import { filesUploaded } from '@/utils/errors/list';

export default function  Attachment({handleChange, attachment, description, remove, id, countryCode, setCountryCode, selector}) {
	const {languages} = useSelector(mediaSettingsSelector);

	const {uploadAtatchment, removeAtatchment, epId} = selector();

	const getAtachmentPath = (group) => `attachments/${group}/${Date.now()}_`;

	const bucketPrefixRef = useRef(getAtachmentPath(epId?.data));
	const useIbmS3UploaderRef = useRef(
		useIbmS3Uploader({
			setFile: (value) => uploadAtatchment({...value, id}),
			removeFile: () => removeAtatchment({id, countryCode}),
			bucketPrefix: bucketPrefixRef.current
		})
	);
	const {handleUpload, handleRemove,updateBucketPrefix} = useIbmS3UploaderRef.current;

	const fileList = useMemo(() => attachment[countryCode] ? [{uid: 0, ...attachment[countryCode]}] : [ ], [attachment[countryCode]]);
	
	const onChange = useCallback(
		(param) => {
			return ({countryCode, value}) => handleChange({param, countryCode, value});
		}, []);

	useEffect(() => {
		const prefix = epId?.data ? `${epId?.data}/${countryCode}` : countryCode;
		updateBucketPrefix(getAtachmentPath(prefix));
	}, [epId?.data, countryCode]);

	return (
		<Card 
			style={{ marginTop: 16 }}
		>
			<Row justify="space-between" align="middle">
				<label>Attachment</label>
				<Select
					value={countryCode}
					style={{ width: 150 }}
					onChange={setCountryCode}
					loading={languages.loading}
				>
					{
						languages.data?.map(({id, nameIso}) =>
							<Option key={id} value={id}>
								<Space>
									{nameIso}
									{attachment[id]  && <CheckOutlined />}
								</Space>
							</Option>)
					} 
				</Select>
			</Row>
			<br/>
			<Validate requirements={[filesUploaded]}>
				<DragUpload
					listType="picture"
					value={fileList}
					title="Attatchment"
					maxCount={1}
					handleUpload={/* handleUpload */ (file) => handleUpload({...file, countryCode})}
					onRemove={handleRemove}
					accept=".docx, .pdf, .zip, .csv, .xml, .pptx, .xlsx"
					multiple={false}
					bucketPrefix={countryCode}
				/>
			</Validate>
			<br/>
			<MultiLanguageInput
				title="Descriptive text"
				value={description}
				onChange={onChange('description')}
				style={{marginVertical: 10}}
				countryCode={countryCode}
				setCountryCode={setCountryCode}
			/>
			<br/>
			<Button type="link" size="small" danger onClick={remove}>Remove</Button>
		</Card>
	);
}