import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Input, Pagination, Row, Select, Space } from 'antd';
const { Search } = Input;

import { fetchEventList } from '@Redux/eventList/eventListFetchers';
import eventListSelector from '@Redux/eventList/eventListSelector';

import EventsTable from '@Components/Tables/EventsTable';
// import FilterSelect from '@Components/ComposedComponents/FilterSelect';

import useNotification from '@/hooks/notifications';
import useCustomSearchParams from '@/hooks/useSearchParams';




const searchTypes = [
	{ label: 'Search by reference ID', value: 'epId' },
	{ label: 'Search by keywords', value: 'search' },
];

const acceptedTypes = ['audio', 'podcast', 'video', 'infographic'];

export default function MediaList() {
	const { data, loading, pagination, error } = useSelector(eventListSelector);

	const {openNotification} = useNotification();

	const {setParam, setParams, searchParams, getData} = useCustomSearchParams({get: fetchEventList, beforeGet: (params) => acceptedTypes.includes(params.type)});

	useEffect(() => {
		setParams({sort: 'mediaDate:desc'});
	}, []);

	useEffect(() => {
		if (error) {
			openNotification({message: 'Something went wrong!', description: error.message || null, type: 'error'});
		}
	}, [error]);
	

	const handlePagination = (page, pageSize) => {
		setParams({page, pageSize});
	};

	const handleSearch = () => {
		setParams({sort: 'relevancy:asc'});
	};
	
	return (
		<Space size="large" direction='vertical' style={{width: '100%'}} >
			<h1>{'Events'}</h1>
			<Search
				addonBefore={
					<Select
						style={{width: 190}}
						options={searchTypes}
						value={searchParams.get("searchType") || 'search'}
						onChange={setParam('searchType')}
					/>
				}
				value={searchParams.get("search") || ''}
				onChange={setParam('search', false)}
				onSearch={handleSearch}
				enterButton
			/>
			{/* <FilterSelect /> */}
			{
			acceptedTypes.includes(searchParams.get("type") || 'audio') ?
			<>
				<EventsTable
					data={data}
					loading={loading}
					sort={searchParams.get("sort") || 'mediaDate:desc'}
					setSort={setParam('sort')}
					getListData={getData}
				/>
				<Row justify="center">
					<Pagination
						total={pagination.totalElements}
						pageSize={pagination.pageSize}
						current={pagination.page}
						showSizeChanger
						showQuickJumper
						hideOnSinglePage
						disabled={loading}
						onChange={handlePagination}
					/>
				</Row>
			</> :
			<p>There are no results for this media type.</p>
			}
		</Space>
	);
}