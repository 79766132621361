export async function cropImage ({img, crop}) {

  const newImage = {...crop};

  if (newImage.unit === '%') {
    newImage.width = img.naturalWidth * crop.width / 100;
    newImage.height = img.naturalHeight * crop.height / 100;
    newImage.x = img.naturalWidth * crop.x / 100;
    newImage.y = img.naturalHeight * crop.y / 100;
  } else {
    newImage.width = crop.width / img.width * img.naturalWidth;
    newImage.height = crop.height / img.height * img.naturalHeight;
    newImage.x = crop.x / img.width * img.naturalWidth;
    newImage.y = crop.y / img.height * img.naturalHeight;
  }

  const offscreen = new OffscreenCanvas(
    newImage.width,
    newImage.height,
  );

  const context = offscreen?.getContext('2d');

  await context.drawImage(img,
    newImage.x,
    newImage.y,
    newImage.width,
    newImage.height,
    0,
    0,
    newImage.width,
    newImage.height,
  );

  const blob = await offscreen.convertToBlob({
    type: 'image/jpeg',
  });

  blob.name = 'cropImage-' + new Date().getTime();

  return blob;
}
