export default {
  process: {
		edit: false,
		loading: false,
		error: null,
		succeed: false
	},
	mediaType: null,
	category: null,
	epId: {
		loading: false,
		error: null,
		data: '',
	},
	title: {},
	description: {},
	tags: [],
	seoTitle: {},
	seoDescription: {},
	seoKeywords: {},
	eventDate: null,
	modifiedDate: null,
	startPublicationDate: null,
	endPublicationDate: null,
	startEmbargoDate: null,
	endEmbargoDate: null,
	termsOfUse: {},
	copyrightAuthor: {},
	copyrightYear: '',
	copyrightLicense: {},
	links:[
		{
			id: 0,
			ref: {},
			description: {}
		}
	],
	attachments: [
		{
			id: 0,
			ref: {},
			description: {}
		}
	],
	files: [],
	multilanguageFiles: {},
	owner: '',
	creationDate: null,
	transcript: {}
};