import { App } from 'antd';

export default function useNotification () {
	const { notification } = App.useApp();
	const openNotification = ({message, description, type = 'info'}) => {
		if(!['info', 'success', 'warning', 'error'].includes(type)) {
			type = 'info';
		}
		notification[type]?.({
			message,
			description,
			duration: 6,
		});
	};

	return {openNotification} ;
}