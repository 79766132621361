import { useState } from "react";
import { Select, Spin, Tabs } from "antd";
import { useSelector } from "react-redux";

import eventInfoSelector from '@Redux/eventInfo/eventInfoSelector';

const options = (name) => [
    {label: 'UNSELECTED ' + name, value: 'unselected'},
    {label: 'SELECTED ' + name, value: 'selected'}
];

export default function Associated ({name, table, otherComponent}) {
    const [option, setOption] = useState('selected');

    const {eventId} = useSelector(eventInfoSelector());

    const Table = table;
    const OtherComponent = otherComponent;

    const items = [
        {
            key: '1',
            label: <span>MANUALLY ASSOCIATED {name} <Select
                    options={options(name)}
                    value={option}
                    onChange={setOption}
                    style={{width: 200, marginLeft: 10}}
                /></span>,
            children: <Table
                selected={option === 'selected'}
                url={`event/${eventId}/medias`}
            />,
        },
        {
            key: '2',
            label: `ASSOCIATED ${name} BY ${name === 'TOPICS' ? 'TAG' : 'METADATA'}`,
            children: <OtherComponent />,
        },
    ];

    return<Spin spinning={false}>
            <Tabs defaultActiveKey="1" items={items} size="large" />
    </Spin>;
}