import { Button, Result, Space, Spin} from 'antd';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import MainInformation from '@Components/ComposedComponents/MainInformation';
import PublishingDates from '@Components/ComposedComponents/PublishingDates';
import SEOInformation from '@Components/ComposedComponents/SEOInformation';
import LinksAndAttachments from '@Components/ComposedComponents/LinksAndAttachments';
import UploadButtons from '@Components/ComposedComponents/UploadButtons';
import ImageUpload from '@Components/ComposedComponents/ImageUpload';
import CropImage from '@Components/ComposedComponents/CropImage';
import ContentProperty from '@Components/ComposedComponents/ContentProperty';


import { TopicContext } from '@/context/topicContext/topicContext';
import { ErrorsContext } from '@/context/errorsContext';
import { LIST_TOPIC } from '@/router/routes';
import useNotification from '@/hooks/notifications';


const imageTooltip = "The filename must use the following convention for thumbnails files: {referenceID}_cover.jpg";

export default function TopicForm () {
	const selector = () => useContext(TopicContext);
  const { setInitialState } = selector();
  const { resetErrors } = useContext(ErrorsContext);
  const {openNotification} = useNotification();
  const navigate = useNavigate();

  const { process } = selector();
  const { loading, edit, succeed } = process;
	const props = {
    selector,
  };

  useEffect(()=> {
		if (edit && succeed) {
			openNotification({message: 'Successful edit!', description: 'The file has been editted successfully.', type: 'success'});
		}
	}, [succeed]);

  useEffect(() => {
    return () => {
      setInitialState({ process: { ...process } });
      resetErrors();
    };
  }, []);

  if (!edit && succeed) {
		return (
			<Result
				status="success"
				title="Successful upload!"
				subTitle="The file has been uploaded successfully."
				extra={[
				<Button type="primary" key="back" onClick={() => navigate(LIST_TOPIC)}>
					Go to Topics List
				</Button>
				]}
			/>
		);
	}

  return (
		<Spin spinning={loading}>
			<Space size="large" direction='vertical' style={{width: '100%'}} >
				{!edit && <h1>{'Topics > Create'}</h1>}
        <MainInformation hasShortDescription {...props}/>
        <br/><br/>
        <SEOInformation hasKeywords={false} {...props}/>
        <br/><br/>
        <PublishingDates simple {...props}/>
        <br/><br/>
        <LinksAndAttachments {...props}/>
        <br/>
        <ImageUpload
          title="Cover Image"
          tooltip={imageTooltip}
          checkNames={null}
          maxCount={1}
          required
          {...props}
        />
        <CropImage {...props}/>
        {edit && <ContentProperty {...props} showOwner={false}/>}
        <UploadButtons {...props}/>
			</Space>
		</Spin>
	);
}