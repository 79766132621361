import { createAsyncThunk } from '@reduxjs/toolkit';
import { getData, postData, deleteData, putData } from '@/utils/api';
import { transformAudioListResults } from '@/utils/transformData/transformResults';


export const fetchElectionsEvents = createAsyncThunk(
	'elections/fetchEvents',

	async (_, {rejectWithValue, getState}) => {
		const {auth: {jwttoken: auth}} = getState();

		const fetchData = async () => {

			try {
				const response = await getData({auth, endpoint: `/elections/events`});
				if (response.error) {
					return rejectWithValue(response);
				}

				return transformAudioListResults(response.content);

			} catch (err) {
				rejectWithValue(err);
			}
		};

		return await fetchData();
	}
);

export const fetchElectionsTopics = createAsyncThunk(
	'elections/fetchTopics',

	async (_, {rejectWithValue, getState}) => {
		const {auth: {jwttoken: auth}} = getState();

		const fetchData = async () => {

			try {
				const response = await getData({auth, endpoint: `/elections/topics`});
				if (response.error) {
					return rejectWithValue(response);
				}

				return transformAudioListResults(response.content);

			} catch (err) {
				rejectWithValue(err);
			}
		};

		return await fetchData();
	}
);

export const selectItem = createAsyncThunk(
	'elections/selectItem',

	async ({id, name = 'events'}, {rejectWithValue, getState}) => {
		const {auth: {jwttoken: auth}} = getState();

		const fetchData = async () => {
			try {
				const response = await postData({auth, endpoint: `/elections/${name}/${id}`});

				if (response.error) {
					return rejectWithValue(response.message);
				}
				return ;

			} catch (err) {
				rejectWithValue(err);
			}
		};

		return await fetchData();
	}
);

export const unselectItem = createAsyncThunk(
	'elections/unselectItem',

	async ({id, name = 'events'}, {rejectWithValue, getState}) => {
		const {auth: {jwttoken: auth}} = getState();

		const fetchData = async () => {
			try {
				const response = await deleteData({auth, endpoint: `/elections/${name}/${id}`});

				if (response.error) {
					return rejectWithValue(response.message);
				}
				return ;

			} catch (err) {
				rejectWithValue(err);
			}
		};

		return await fetchData();
	}
);

export const itemPosition = createAsyncThunk(
	'elections/itemPosition',

	async ({id, position, name = 'events'}, {rejectWithValue, getState}) => {
		const {auth: {jwttoken: auth}} = getState();

		const fetchData = async () => {
			try {
				const response = await putData({auth, endpoint: `/elections/${name}/${id}?position=${position}`});

				if (response.error) {
					return rejectWithValue(response.message);
				}
				return ;

			} catch (err) {
				rejectWithValue(err);
			}
		};

		return await fetchData();
	}
);
