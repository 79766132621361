export default {
	process: {
		edit: false,
		loading: false,
    error: null,
    succeed: false
	},
	title: {},
  shortDescription: {},
	description: {},
	tags: [],
	seoTitle: {},
	seoDescription: {},
	startPublicationDate: null,
	endPublicationDate: null,
	links:[
		{
			id: 0,
			ref: {},
			description: {}
		}
	],
	attachments: [
		{
			id: 0,
			ref: {},
			description: {}
		}
	],
	creationDate: null,
	editDate: null,
	coverImage: null,
	files: []
};