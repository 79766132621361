import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Space } from 'antd';


import electionsSelector from '@Redux/elections/electionsSelector';
import { fetchElectionsEvents, fetchElectionsTopics, itemPosition } from '@Redux/elections/electionsFetcher';

import useNotification from '@/hooks/notifications';

import SortableTable from '@Components//Tables/SortableTable';

export default function Order({name}) {

	const dispatch = useDispatch();
	const { openNotification } = useNotification();

	const { selectedTopics, selectedEvents, process } = useSelector(electionsSelector);

  const { data, loading, error } = useMemo(() =>{
		const {data, ...rest} = name === 'topics'? selectedTopics : selectedEvents;

    return {
      ...rest,
      data: [...data].sort((a, b) => a?.position - b?.position)

    };
  }, [name, selectedTopics, selectedEvents]);

	const [loadingChange, setLoadingChange] = useState(false);

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		if (error) {
			showError(error);
		}
	}, [error]);

	useEffect(() => {
		if (process.error) {
			showError(process.error);
		}
	}, [process.error]);

	const getData = () => {
    if (name === 'topics') {
      dispatch(fetchElectionsTopics());
    } else {
      dispatch(fetchElectionsEvents());
    }
  };
	
	function showError (error) {
		openNotification({message: 'Something went wrong!', description: error.message || null, type: 'error'});
	}

	const saveItems = async (items) => {
		setLoadingChange(true);
		Promise.all(items.map(({id, position}) => dispatch(itemPosition({id, position, name})) ))
			.then(() => {
				setLoadingChange(false);
				getData();
			})
			.catch(err => {
				showError(err);
			});
	};

	return (
		<Space size="large" direction='vertical' style={{width: '100%'}} >
			<>
				<SortableTable
					data= {data}
					loading={loading || loadingChange}
					saveItems={saveItems}
					singleDate={name == 'topics'}
					updateDate={name == 'topics'}
					idKey={name == 'topics' ? 'epId' : 'eventId'}
					name={name}
				/>
			</>
		</Space>
	);
}