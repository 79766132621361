import { useContext } from 'react';

import MainInformation from '@Components/ComposedComponents/MainInformation';
import PublishingDates from '@Components/ComposedComponents/PublishingDates';
import SEOInformation from '@Components/ComposedComponents/SEOInformation';
import CopyrightInformation from '@Components/ComposedComponents/CopyrightInformation';
import LinksAndAttachments from '@Components/ComposedComponents/LinksAndAttachments';
import AudioBulkUpload from '@Components/ComposedComponents/AudioBulkUpload';
import Transcript from '@Components/ComposedComponents/Transcript';
import ContentProperty from '@Components/ComposedComponents/ContentProperty';
import UploadButtons from '@Components/ComposedComponents/UploadButtons';

import { MediaContext } from '../../context/mediaContext/mediaContext';

export default function AudioForm () {
	const selector = () => useContext(MediaContext);

	const props = {
		selector,
	};

	return (
		<>
			<MainInformation {...props}/>
			<br/><br/>
			<SEOInformation {...props}/>
			<br/><br/>
			<PublishingDates {...props}/>
			<br/><br/>
			<CopyrightInformation {...props}/>
			<br/><br/>
			<LinksAndAttachments {...props}/>
			<br/><br/>
			<AudioBulkUpload {...props}/>
			<br/><br/>
			<Transcript {...props}/>
			<br/><br/>
			<ContentProperty {...props}/>
			<br/><br/>
			<UploadButtons {...props}/>
		</>
	);
}