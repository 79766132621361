import { Col, Row, Select, Typography } from 'antd';
const {Text} = Typography;

export default function BasicSelect ({title, value, onChange, options, error = {isOk: true}, ...rest}) {
	return (
		<>
			<Row align="middle">
				<Col span={8}>
					<label>{title}</label>
				</Col>
				<Col span={16}>
					<Select
						title={title}
						style={{ width: 200 }}
						value={value}
						onChange={onChange}
						options={options}
						status={error?.show && error.type}
						{...rest}
					/>
				</Col>
			</Row>
			<Row align="middle">
				<Col offset={8} span={16}>
					<Text type={error.type === 'error' ? 'danger' : 'warning'}>{error?.text}</Text>
				</Col>
			</Row>
		</>
		
	);
}