/* eslint-disable no-undef */

import {getSetting} from '@/utils/settings' ;

function responseCodeOk (code) {
	if (!code) {
		return true;
	}

	return parseInt(code) < 300 && parseInt(code) >= 200;
}

export async function postData ({data, endpoint, auth = null, noResponse = false}) {
	const url = getSetting('REACT_APP_BASE_URL') + endpoint;
	try {
		const response = await fetch(url, {
			method: 'post',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
				'Accept-Language': 'en-GB',
				...(auth? {'X-API-KEY': auth}: {})
			}
		});

		if (response.ok && noResponse) {
			return { error: false };
		}

		const json = await response.json();

		if (!response.ok) {
			return {error: true, ...json};
		}

		if (json && !responseCodeOk(json.contentResponse?.code)) {
			return {error: true, message: json.contentResponse.error};
		}

		return {error: false, content: json?.content || {}};

	} catch (err) {
		return {error: true, ...err};
	}
}

export async function getData ({auth, endpoint }) {
	const version = (endpoint.includes('?') ? '&' : '?') + 'v=' + new Date().getTime();
	const url = getSetting('REACT_APP_BASE_URL') + endpoint + version;

	try {
		const response = await fetch(url, {
			method: 'get',
			cache: 'no-cache',
			headers: { 
				'Content-Type': 'application/json',
				'Accept-Language': 'en-GB',
				'X-API-KEY': auth
			}
		});

		if (!response.ok) {
			const json = await response.json();
			return {error: true, ...json};
		}

		if (response.status === 204) {
			return {error: false, content: []};
		}

		const json = await response.json();

		if (!responseCodeOk(json.contentResponse?.code)) {
			return {error: true, message: json.contentResponse.error};
		}

		return {error: false, content: json.content || json};

	} catch (err) {
		console.log(err);
		return {error: true, message: 'Something whent wrong!'};
	}
}

export async function deleteData ({auth, endpoint }) {
	const url = getSetting('REACT_APP_BASE_URL') + endpoint;

	try {
		const response = await fetch(url, {
			method: 'delete',
			cache: 'no-cache',
			headers: { 
				'Content-Type': 'application/json',
				'Accept-Language': 'en-GB',
				'X-API-KEY': auth
			}
		});

		if (!response.ok) {
			const json = await response.json();
			return {error: true, ...json};
		}

		const json = await response?.json?.();

		if (json && responseCodeOk(json.contentResponse?.code)) {
			return {error: true, message: json.contentResponse.error};
		}

		return {error: false, content: json.content};

	} catch (err) {
		return {error: true, ...err};
	}
}

export async function deleteDataNew ({auth, endpoint }) {
	const url = getSetting('REACT_APP_BASE_URL') + endpoint;

	try {
		const response = await fetch(url, {
			method: 'delete',
			cache: 'no-cache',
			headers: { 
				'Content-Type': 'application/json',
				'Accept-Language': 'en-GB',
				'X-API-KEY': auth
			}
		});

		if (!response.ok) {
			const json = await response.json();
			return {error: true, ...json};
		}

		return {error: false, content: null};

	} catch (err) {
		return {error: true, ...err};
	}
}

export async function putData ({ endpoint, data, auth = null }) {
	const url = getSetting('REACT_APP_BASE_URL') + endpoint;

	try {
		const response = await fetch(url, {
			method: 'put',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
				'Accept-Language': 'en-GB',
				...(auth? {'X-API-KEY': auth}: {})
			}
		});

		const json = await response.json();

		if (!response.ok) {
			return {error: true, ...json};
		}

		if (json?.contentResponse && responseCodeOk(json.contentResponse?.code)) {
			return {error: true, message: json.contentResponse.error};
		}

		return {error: false, content: json?.content || json};

	} catch (err) {
		return {error: true, ...err};
	}
}