import { useDispatch } from 'react-redux';

import { Button, ConfigProvider, Select, Space, Table } from 'antd';
import Icon, {CloseOutlined, CheckOutlined} from '@ant-design/icons';


import ExternalLink from '@Components/icons/ExternalLink';
import BasicEmpty from '@Components/BasicComponents/BasicEmpty';

import { localTimeFormated } from '@/utils/dates';
import { getPreview } from '@/utils/settings';
import { pluralTosingular } from '@/utils/plurals';

const orderTypes = (isTopics) => [
	{ label: 'Relevancy', value: 'relevancy:asc' },
	...(!isTopics ? [
		{ label: 'Event Date: newest', value: 'mediaDate:desc' },
		{ label: 'Event Date: oldest', value: 'mediaDate:asc' }
	]: []),
	{ label: 'Last Update: newest', value: 'modified:desc' },
	{ label: 'Last Update: oldest', value: 'modified:asc' },
];

const keys = {
	topics: 'topicKey',
	medias:'mediaId',
	events: 'eventId'
};

const buttonStyles = { fontSize: '16px', display: 'flex', alignItems: 'center' };

export default function AssociatedTable ({data, loading, sort, setSort, getListData, type, name, selectItem, unselectItem, beforeSelect = null, dispatched = true, blocked = false}) {
	const dispatch = useDispatch();

	const columns = [
		{
			title: 'Reference ID',
			dataIndex: 'epId',
			key: 'epId',
			width: 200,
		},
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
			style: { wordWrap: 'break-word', wordBreak: 'break-word' }
		},
		( name !== 'topics' ? {
				title: name === 'events' ? 'Start Date' : 'Media Date',
				dataIndex: 'eventDate',
				key: 'eventDate',
				render: (_, item) => {
					return localTimeFormated(item.eventDate);
				},
				width: 150,
			} : {
				title: 'Update Date',
				dataIndex: 'updateDate',
				key: 'updateDate',
				render: (_, item) => {
					return localTimeFormated(item.updateDate);
				},
				width: 150,
			}
		),
		(
			name === 'events' ? {
				title: 'End Date',
				dataIndex: 'eventEndDate',
				key: 'eventEndDate',
				render: (_, item) => {
					return localTimeFormated(item.eventDate);
				},
				width: 150,
			} : {}
		),
		{
			title: sort ? <label  style={{width: '100%'}}>Order by: <Select options={orderTypes(name === 'topics')} value={sort} onChange={setSort} size='Large' style={{width: 170}}/></label> : 'Actions',
			key: 'action',
			width: 300,
			render: (_, element) => (
				<Space size="middle">
					{element.blocked || blocked ?
						<Button
						disabled={true}
							style={buttonStyles}
							onClick={() => unselect(element[keys[name]] || element.key)}
							type="primary"
						>
							<CloseOutlined />
							Unselect
						</Button>
					: element.selected ?
						<Button
							style={buttonStyles}
							onClick={() => unselect(element[keys[name]] || element.key)}
							type="primary"
						>
							<CloseOutlined />
							Unselect
						</Button> :
						<Button
							style={buttonStyles}
							onClick={() => select(element[keys[name]] || element.key)}
						>
							<CheckOutlined />
							Select
						</Button>
					}
					<Button
						href={getPreview(pluralTosingular(type), element.epId)}
						target='_blank'
						style={{paddingInline: 5}}
					>
						<Icon component={ExternalLink} color='black'  />
					</Button>
				</Space>
			),
		},
	];

	if (name !== 'events') delete columns[3];

	const select = async (id) => {
		let run = true;

		if (beforeSelect) {
			run = beforeSelect(id);
		}

		if (run) {
			if (dispatched) {
				await dispatch(selectItem({name, id}));
			} else {
				await selectItem(id);
			}
			await getListData();
		}
	};

	const unselect = async (id) => {
		if (dispatched) {
			await dispatch(unselectItem({name, id}));
		} else {
			await unselectItem(id);
		}
		await getListData();
	};


	return (
		<>
		<ConfigProvider renderEmpty={() => BasicEmpty({text:"No results"})}> 
			<Table
				dataSource={data}
				columns={columns}
				loading={loading}
				pagination={false}
			/>
			</ConfigProvider>
		</>
	);
}