import { createAsyncThunk } from '@reduxjs/toolkit';
import { getData } from '@/utils/api';
import { transformAudioListResults } from '@/utils/transformData/transformResults';

export const fetchTopicsList = createAsyncThunk(
	'topicsList/fetchList',

	async ({pageSize = 20, page = 1, sort = 'mediaDate:desc', searchType = 'topicKey', search = ''}, {rejectWithValue, getState}) => {
		const {auth: {jwttoken: auth}} = getState();

		const fetchData = async () => {
			try {
				const response = await getData({auth, endpoint: `/topics?pageSize=${pageSize}&page=${page}&sort=${sort}&${searchType}=${search}`});

				if (response.error) {
					return rejectWithValue(response);
				}

				return transformAudioListResults(response.content);

			} catch (err) {
				rejectWithValue(err);
			}
		};

		return await fetchData();
	}
);
