import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import authSelector from '@Redux/auth/authSelector';
import useRunOnFirstMount from "./useRunOnFirstMount";


export default function useCustomSearchParams({get, beforeGet, loadData = true}) {
	const dispatch =useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const { jwttoken } = useSelector(authSelector);

	useRunOnFirstMount(() => {
		if (jwttoken && loadData) {
			getData();
		}
	});

	const getData = async (lastParams = {}) => {
		let params = {
			type: 'audio',
			searchType: 'search'
		};
		for(const [key, value] of searchParams.entries()) {
			params[key] = value;
		}

		params = {...params, ...lastParams};

		if (!beforeGet || beforeGet(params)) {
			await dispatch(get(params));
		}
	};

	const setParams = (params, loadData = true) => {
		setSearchParams(parameters => {
			Object.entries(params).forEach(([key, value]) => {
				parameters.set(key, value);
			});
			return parameters;
		});

		if (loadData) {
			getData(params);
		}
	};

	const setParam = (name, loadData = true) => {
		return (param) => {
			const value = param?.target ? (param?.target?.value || '') : param;
			setParams({[name]: value}, loadData);
		};
	};

	const deleteParam = (param) => {
		searchParams.delete(param);
	};


	return {setParams, setParam, searchParams, getData, deleteParam};

}