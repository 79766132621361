import { Modal } from "antd";
import { useState, createContext, useRef } from "react";
import useCustomPrompt from "@/hooks/useCustomPrompt";

export const GoBackContext = createContext({});

export function GoBackContextProvider({ children }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
	const okCb = useRef(() => {});
  const [areChanges, setAreChanges] = useState(false);

  const handleOk = () => {
    okCb.current();
    setIsModalOpen(false);
    setAreChanges(false);
    okCb.current = () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const setOkCb = (cb) => {
    okCb.current = cb;
  };

  const openModal = (cb) => {
    okCb.current = cb;
    setIsModalOpen(true);
  };

  useCustomPrompt(openModal, areChanges);

  return (
    <GoBackContext.Provider
      value={{
        setIsModalOpen,
        okCb,
        setOkCb,
        setAreChanges,
        areChanges
      }}
    >
      <Modal title="Warning!" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>You have made modifications that will be lost if you leave the page.</p>
      </Modal>
      {children}
    </GoBackContext.Provider>
  );
}