import { combineReducers, configureStore } from '@reduxjs/toolkit';

import authReducer from '@Redux/auth/authSlice';
import mediaSettingsSlice from '@Redux/mediaSettings/mediaSettingsSlice';
import uploadErrorsSlice from '@Redux/uploadErrors/uploadErrorsSlice';
import mediaListSlice from '@Redux/mediaList/mediaListSlice';
import eventListSlice from '@Redux/eventList/eventListSlice';
import eventInfoSlice from '@Redux/eventInfo/eventInfoSlice';
import topicsListSlice from '@Redux/topicsList/topicsListSlice';
import electionsSlice from '@Redux/elections/electionsSlice';

const rootReducer = combineReducers({
	auth: authReducer,
	mediaSettings: mediaSettingsSlice,
	uploadErrors: uploadErrorsSlice,
	mediaList: mediaListSlice,
	eventList: eventListSlice,
	eventInfo: eventInfoSlice,
	topicsList: topicsListSlice,
	elections: electionsSlice,
});


export const store = configureStore({
	reducer: rootReducer,
});
