import { Layout, Menu } from 'antd';
const { Sider } = Layout;

import items from './items';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';


export default function SideMenu() {
	const location = useLocation();

	const selectedKeys = useMemo(() => location.pathname.split('/').slice(1), [location.pathname]);

	return (
		<Sider
			width={300}
			className='side-menu'
		>
			<Menu
				mode="inline"
				/* defaultOpenKeys={['homeAndOthers']} */
				selectedKeys={selectedKeys}
				style={{
					minHeight: '100%',
					borderRight: 0,
					paddingTop: 20,
					paddingBottom: 20
				}}
				items={items}
			/>
		</Sider>
				
	);
}