import { useRef, useEffect } from 'react';

import Validate from '@Components/Validate';
import BulkUpload from '@Components/BasicComponents/BulkUpload';

import { filesUploaded, fileRequired } from '@/utils/errors/list';

import useNotification from '@/hooks/notifications';
import useIbmS3Uploader from '@/hooks/useIbmS3UploaderNew';

export default function ImageUpload ({selector, title, tooltip, checkNames, maxCount = null, required = false}) {
	const {files, epId, setFile, removeFileByUid} = selector();
	const {openNotification} = useNotification();

	const useIbmS3UploaderRef = useRef(useIbmS3Uploader({setFile, removeFile: removeFileByUid, bucketPrefix: epId?.data + '/images'}));
	const {handleUpload, handleRemove, updateBucketPrefix} = useIbmS3UploaderRef.current;

	useEffect(() => {
		const prefix = epId?.data ? `thumbnails/${epId?.data }/`: 'thumbnails/';
		updateBucketPrefix(prefix);
	}, [epId?.data]);

	const beforeUpload = () => {
		if (maxCount && files.length >= maxCount) {
			openNotification({
        type: 'error',
				message: 'Too many images',
        description: `You can only upload ${maxCount} images.`
      });

      return false;
		}

		return checkNames?.({id: epId?.data, openNotification});
	};

	return <>
		<Validate requirements={[filesUploaded, ...(required ?  [fileRequired] : [])]}>
			<BulkUpload
				title={title}
				value={files}
				handleUpload={handleUpload}
				accept="image/jpeg"
				beforeUpload={beforeUpload}
				handleRemove={handleRemove}
				tooltip={tooltip}
			/>
		</Validate>
	</>;
}