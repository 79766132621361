import { useContext } from 'react';
import { useSelector} from 'react-redux';

import { Col, Row, Space } from 'antd';

import mediaSettingsSelector from '@Redux/mediaSettings/mediaSettingsSelector';

import BasicSelect from '@Components/BasicComponents/BasicSelect';
import Validate from '@Components/Validate';
import BasicInput from '@Components/BasicComponents/BasicInput';

import { required } from '@/utils/errors/list';
import { transformMediaType } from '@/utils/basics';
import { uploadedAttachments } from '@/utils/basics';

import { MediaContext } from '@/context/mediaContext/mediaContext';


const { Compact } = Space;
let timeoutId = null;

const includeGetEpid = ['EPV_AUDIO'];

export default function MediaDefinition ({exclude, edit = false}) {
	const {
		mediaType,
		category,
		epId,
		files,
		attachments,
		setValue,
		setInitialState,
		setEpIdValue,
		getEpId
	} = useContext(MediaContext);
	const { mediaTypes } = useSelector(mediaSettingsSelector);

	const handleMediaType = (value) => {
		if (mediaType) {
			setInitialState();
		}
		setValue({param: 'mediaType', value});
	};

	const handleCategory = (value) => {
		setValue({param: 'category', value});
		if (!edit && includeGetEpid.includes(mediaType) && !files.length && !uploadedAttachments(attachments)) {
			getEpId({
				data: {category: value},
				type: transformMediaType(mediaType)
			});
		}
	};

	const handleId = (value) => {
		setEpIdValue(value);

		if (timeoutId !== null) {
			clearTimeout(timeoutId);
		}

		timeoutId = setTimeout( () =>
			getEpId({
				data: {category, epId: value },
				type: transformMediaType(mediaType)
			})
		, 1000);
	};

	const filterTypes = (types) => {
		if (!exclude || exclude?.length === 0) {
			return types;
		}

		return types.filter( type => !exclude.includes(type.value));
	};
	
	return (
		<Compact block size="middle" direction='vertical'>
			<Row justify={{xl: 'space-between', xxl: 'space-around'}} align="top">
				<Col>
					<Space>
						<Validate requirements={[required]}>
							<BasicSelect
								title='Media Type'
								value={mediaType}
								onChange={handleMediaType}
								options={filterTypes(mediaTypes.data)}
								disabled={edit}
							/>
						</Validate>
					</Space>
				</Col>
				<Col>
					<Space>
						<Validate requirements={[required]}>
							<BasicSelect
								title='Category'
								value={category}
								onChange={handleCategory}
								options={mediaTypes.categories[mediaType]}
								disabled={!mediaType}
							/>
						</Validate>
					</Space>
					
				</Col>
				<Col>
					<Space>
						<Validate requirements={[required]} error={epId.error && {isOk: false, text: epId.error.message}}>
							<BasicInput
								title="Reference ID"
								inline
								value={epId.data}
								onChange={handleId}
								disabled={!category || files.length || uploadedAttachments(attachments) || edit}
								loading={epId.loading}
							/>
						</Validate>
					</Space>
				</Col>
			</Row>
			<br />
		</Compact>
	);
}