import { useSelector } from 'react-redux';

import authSelector from '@Redux/auth/authSelector';

import IbmS3Uploader from '@/utils/ibmS3Uploader';
import { getSetting } from '@/utils/settings';
import { isImageFile } from '../utils/names';


var ibmS3BucketName = getSetting('ibmS3Bucket');

export default function useIbmS3Uploader ({setFile , removeFile, bucketPrefix = ''}) {
	const { jwttoken } = useSelector(authSelector);

	const uploader = new IbmS3Uploader({
		'endpoint': 's3.eu-de.cloud-object-storage.appdomain.cloud',
		's3tokenApi': getSetting('REACT_APP_BASE_URL')+'/s3token',
		'bucketName' : ibmS3BucketName,
		'authToken':jwttoken,
		...(bucketPrefix !== '' ? {bucketPrefix} : {}),
	});

    const callback = (name, countryCode, typeName) => {
		return ({type, data}) => {
			const fileData = {
				'INIT': {
					uid: data.handlerId,
					name,
					status: 'uploading',
					percent: 0,
					countryCode,
					type: typeName
				},
				'PERCENT': {
					uid: data.handlerId,
					name,
					status: 'uploading',
					percent: data.percent,
					countryCode,
					type: typeName
				},
				'COMPLETED': {
					uid: data.handlerId,
					name,
					status: 'done',
					url: data.location,
					countryCode,
					type: typeName,
					...(isImageFile(data.location) ? {thumbUrl:  data.location} : {}),
				},
				'ERROR': {
					uid: data.handlerId,
					name,
					status: 'error',
					countryCode,
					type: typeName
				}
			};

			setFile(fileData[type]);
		};

	};

	const handleUpload = ({file, countryCode = null, type = null}) => {
		uploader.addFile(file, callback(file.name || new Date().getTime(), countryCode, type));
		uploader.run();
	};

	const handleRemove = (file, countryCode = null, type = null) => {
		uploader.remove(file.uid);
		removeFile({uid: file?.uid, countryCode, type});
	};

	const updateBucketPrefix = (bucketPrefix) => {
		uploader.setBucketPrefix(bucketPrefix);
	};

	return { handleUpload, handleRemove, updateBucketPrefix};
}