import { useEffect, useState, useRef, useContext } from 'react';

import { findErrors } from '@/utils/errors';

import { v4 as uuid } from 'uuid';
import { ErrorsContext } from '@/context/errorsContext';

export default function useValidate({value, element, requirements}) {
	const { addVerifyFunction } = useContext(ErrorsContext);
	const id = useRef(null);
	const [err, setErr] = useState({isOk: true, text: ""});

	const getErrors = ({value, requirements}) => {
		const error = findErrors(value, requirements, element);

		setErr(error);

		return error;
	};

	useEffect(() => {
		id.current = id.current || uuid();
		addVerifyFunction({id: id.current, func: () => getErrors({value, requirements})});
	}, [value]);

	return err;
}