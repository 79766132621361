import { useEffect, useRef } from "react";

export default function useRunOnFirstMount (cb) {
    const firstMount = useRef(true);

    useEffect(() => {
		if (firstMount.current) {
			cb();
			firstMount.current = false;
		}
	}, []);
}