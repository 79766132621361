import { useContext } from 'react';
import { ErrorsContext } from '@/context/errorsContext';


export default function useFindErrors() {
	const { verifyFunctions } = useContext(ErrorsContext);

	function findErrors() {
		const functionsArray = Object.values(verifyFunctions.current);
		let ok = true;
		let errors = [];

		functionsArray.forEach(func => {
			const res = func();
			if (!res.isOk) {
				errors = [...errors, `${res.element}: ${res.text}`];
				ok = false;
      }
		});

		return {
			ok,
      errors
		};
	}

	return {findErrors};
}