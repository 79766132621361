import React, { useContext, useState } from 'react';

import { Space, Tabs, Typography } from 'antd';

import Details from '@Components/Elections/Details';
import { GoBackContext } from '@/context/goBackContext';

const { Title } = Typography;

export default function Elections () {
	const [ activeKey, setActiveKey ] = useState('1');

	const { setIsModalOpen, setOkCb, areChanges } = useContext(GoBackContext);

	const items = [
		{
				key: '1',
				label: 'TOPICS',
				children: <Details name="topics" />,
		},
		{
				key: '2',
				label: 'EVENTS',
				children: <Details name="events" />,
		},
	];

	const handleChange = ( key ) => {
		if ( !areChanges ) {
			setActiveKey( key );
			return;
		}

		setActiveKey(activeKey);
		setIsModalOpen(true);
		setOkCb(() => setActiveKey(key));
	};

	return (
		<>
			<Space size="large" direction='vertical' style={{width: '100%'}} >
				<Title>European Elections 2024</Title>
			</Space>
			<Tabs
				activeKey={activeKey}
				onChange={handleChange}
				items={items}
				type="card"
				size="large"
				destroyInactiveTabPane
			/>
		</>
	);
}