import { createAsyncThunk } from '@reduxjs/toolkit';
import { getData } from '@/utils/api';
import { transformFetchedData } from '@/utils/transformData';

export const getItemData = createAsyncThunk(
	'event/getItemData',

	async ({epId}, {rejectWithValue, getState}) => {
		const {auth: {jwttoken: auth}} = getState();
		const fetchData = async () => {
			try {
				const response = await getData({auth, endpoint: `/event/${epId}`});

				if (response.error) {
					return rejectWithValue(response);
				}

				return transformFetchedData(response.content, 'event');

			} catch (err) {
				rejectWithValue(err);
			}
		};

		return await fetchData();
	}
);
