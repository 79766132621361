import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input, Pagination, Row, Select, Space } from 'antd';
import Title from 'antd/es/typography/Title';
const { Search } = Input;

import { fetchTopicsList } from '@Redux/topicsList/topicsListFetchers';
import { fetchEventList } from '@Redux/eventList/eventListFetchers';
import topicsListSelector from '@Redux/topicsList/topicsListSelector';
import eventListSelector from '@Redux/eventList/eventListSelector';
import { fetchElectionsEvents, fetchElectionsTopics, selectItem, unselectItem } from '@Redux/elections/electionsFetcher';
import electionsSelector from '@Redux/elections/electionsSelector';


import AssociatedTable from '@Components/Tables/AssociatedTable';

import useNotification from '@/hooks/notifications';
import useCustomSearchParams from '@/hooks/useSearchParams';

export default function Selection({name}) {
  const dispatch = useDispatch();

	const topics = useSelector(topicsListSelector);
  const events = useSelector(eventListSelector);
  const { selectedTopics, selectedEvents, process } = useSelector(electionsSelector);

  const {openNotification} = useNotification();
	const {setParam, setParams, searchParams, getData} = useCustomSearchParams({get: name === 'topics'? fetchTopicsList : fetchEventList});

  const idValue = name === 'topics'? 'topicKey' :'epId';

  const searchTypes = [
    { label: 'Search by reference ID', value: idValue},
    { label: 'Search by keywords', value: 'search' },
  ];

  const selectedData = useMemo(() => {
    const {data, ...rest} = name === 'topics'? selectedTopics : selectedEvents;

    return {
      ...rest,
      data: data.map(item => {
        return { ...item, selected: true };
      })
    };
  }, [name, selectedTopics, selectedEvents]);

  const { data, loading, pagination, error } = useMemo(() =>{
    const {data, ...rest} = name === 'topics'? topics : events;

    return {
      ...rest,
      data: data.map(item => {
        const index = selectedData.data.findIndex(i => i.key == item.key);
        const selected = index!== -1;

        return {...item, selected };
      })
    };
  }, [name, topics, events, selectedData]);

  useEffect(() => {
    if (name === 'topics') {
      setParams({sort: 'modified:desc'});
    } else {
      setParams({sort: 'mediaDate:desc'});
    }

    getSelected();

    const searchType = searchParams.get('searchType');

    if (searchType && (searchType !== 'search' || searchType !== idValue)) {
      setParams({searchType: idValue});
    }
  }, []);

	useEffect(() => {
		if (error) {
			openNotification({message: 'Something went wrong!', description: error.message || null, type: 'error'});
		}
	}, [error]);

  useEffect(() => {
		if (selectedData.error) {
			openNotification({message: 'Something went wrong!', description: selectedData.error.message || null, type: 'error'});
		}
	}, [selectedData.error]);

  const getSelected = () => {
    if (name === 'topics') {
      dispatch(fetchElectionsTopics());
    } else {
      dispatch(fetchElectionsEvents());
    }
  };

	const handlePagination = (page, pageSize) => {
		setParams({page, pageSize});
	};

	const handleSearch = () => {
		setParams({sort: 'relevancy:asc'});
	};

	const getListData = async () => {
    getData();
    getSelected();
	};

  const beforeSelect = () => {
    if (selectedData.data.length > 5) {
      openNotification({message: 'Something went wrong!', description: "It is not possible to select more than 6 items. Please unselect at least one item before proceeding" || null, type: 'error'});
      return false;
    }

    return true;
  };

	return (
		<Space size="large" direction='vertical' style={{width: '100%'}} >
      <Title level={3}>Selected {name}</Title>
      <AssociatedTable
        data={selectedData?.data}
        loading={selectedData?.loading}
        getListData={getListData}
        type={name}
        name={name}
        selectItem={selectItem}
        unselectItem={unselectItem}
      />
      <Title level={3}>Unselected {name}</Title>
			<Search
				addonBefore={
					<Select
						style={{width: 190}}
						options={searchTypes}
						value={searchParams.get("searchType") || 'search'}
						onChange={setParam('searchType')}
					/>
				}
				value={searchParams.get("search") || ''}
				onChange={setParam('search', false)}
				onSearch={handleSearch}
				enterButton
			/>
			<>
				<AssociatedTable
					data={data}
					loading={loading || process.loading}
					sort={searchParams.get("sort") || 'mediaDate:desc'}
					setSort={setParam('sort')}
					getListData={getListData}
					type={name}
          name={name}
          beforeSelect={beforeSelect}
          selectItem={selectItem}
          unselectItem={unselectItem}
				/>
				<Row justify="center">
					<Pagination
						total={pagination.totalElements}
						pageSize={pagination.pageSize}
						current={pagination.page}
						showSizeChanger
						showQuickJumper
						hideOnSinglePage
						disabled={loading}
						onChange={handlePagination}
					/>
				</Row>
			</>
		</Space>
	);
}