import { useCallback, useState } from "react";

export default function useContextState ({initialData, initialStateData}) {
  const [countryCode, setCountryCode] = useState('en_GB');
  const [data, setData] = useState(initialData);

  const setValue = ({param, countryCode, value}) => {

    setData((currentData) => {
      let paramData = currentData[param];

      if (countryCode) {
        paramData[countryCode] = value;
      } else {
        paramData = value;
      }

      return {
        ...currentData,
        [param]: paramData
      };
    });
	};

	const setTags = (value) => {
		setData({
			...data,
      tags: value
		});
	};

	const editListItem = ({list, id, param, countryCode, value}) => {
		setData(currentData => {
      const newList = [...currentData[list]];
      newList.find(item => item.id === id)[param][countryCode] = value;

      return {
        ...currentData,
        [list]: newList
      };
  });
	};

	const addToList = ({ list, object }) => {
		setData({
			...data,
      [list]: [
				...data[list],
				object
      ]
		});
	};

	const removeFromList = ({ list, id }) => {
		const index = data[list].findIndex(item => item.id === id);
		const newState = {...data};
		if (index !== -1) {
			newState[list].splice(index, 1);
		}
		setData(newState);
	};

  const setInitialState = useCallback((values) => {
    setData({
      ...JSON.parse(JSON.stringify({...initialStateData})),
      ...values
    });
  }, []);

  const uploadAtatchment = (value) => {
    const {id, countryCode} = value;
    setData((currentData) => {
      const attachments = [...currentData.attachments];
      attachments.find(item => item.id === id).ref[countryCode] = value;

      return {
        ...currentData,
        attachments
      };
    });
  };

  const removeAtatchment = ({id, countryCode}) => {
    setData((currentData) => {
      const attachments = [...currentData.attachments];
      attachments.find(item => item.id === id).ref[countryCode] = null;
      return {
        ...currentData,
        attachments,
      };
    });
  };

  const setFile = (file) => {
    const {uid} = file;
    setData((currentData) => {
      const index = currentData.files.findIndex(item => item.uid === uid);
      const files = [...currentData.files];

      if ( index === -1 ) {
        files.push(file);
      }
      else {
        files[index] = file;
      }

      return {
        ...currentData,
        files
      };
    });
  };

  const removeFileByUid = ({uid}) => {
    setData((currentData) => {
      const files = [...currentData.files];
      const index = files.findIndex(item => item.uid === uid);

      if (index !== -1) {
        files.splice(index, 1);
      }

      return {
        ...currentData,
        files
      };
    });
  };

  const setSimpleFile = (paramName) => {
    return (file) => {
      setData((currentData) => {
        return {
          ...currentData,
          [paramName] : file
        };
      });
    };
  };

  const removeSimpleFile = (paramName) => {
    return () => {
      setData((currentData) => {
        return {
          ...currentData,
          [paramName] : null
        };
      });
    };
  };


  const setProcess = (process) => {
    setData((currentData) => {
      return {
        ...currentData,
        process: {
          ...currentData.process,
          ...process
        }
      };
    });
  };

  const resetProcess = () => {
    setProcess({
      loading: false,
      error: null,
      succeed: false
    });
  };

  const setMultilanguageFiles = (file) => {
    const {uid, countryCode, type} = file;
    setData((currentData) => {
      let files = type ? currentData.multilanguageFiles[type] : currentData.multilanguageFiles;

      if (!files) {
        files = {};
      }

      files =  files[countryCode];

      if (!files) {
        files = [];
      }

      const index = files.findIndex(item => item.uid === uid);

      if ( index === -1 ) {
        files.push(file);
      }
      else {
        files[index] = file;
      }

      if ( type ) {
        return {
          ...currentData,
          multilanguageFiles: {
          ...currentData.multilanguageFiles,
            [type]: {
            ...currentData.multilanguageFiles[type],
              [countryCode]: files
            }
          }
        };
      } else {
        return {
          ...currentData,
          multilanguageFiles: {
            [countryCode]: files
          }
        };
      }
    });
  };

  const removeMultilanguageFilesByUid = ({uid, countryCode, type}) => {
    setData((currentData) => {
      const files = [...(type ? currentData.multilanguageFiles[type][countryCode] : currentData.multilanguageFiles[countryCode])];

      const index = files.findIndex(item => item.uid === uid);

      if (index !== -1) {
        files.splice(index, 1);
      }

      if (type) {
        return {
          ...currentData,
          multilanguageFiles: {
          ...currentData.multilanguageFiles,
            [type]: {
            ...currentData.multilanguageFiles[type],
              [countryCode]: files
            }
          }
        };
      }

      return {
        ...currentData,
        multilanguageFiles: {
          ...currentData.multilanguageFiles,
          [countryCode]: files
        }
      };
    });
  };

  return {
    data,
    setData,
    countryCode,
    setCountryCode,
    ...data,
    setInitialState,
    setValue,
    setTags,
    editListItem,
    addToList,
    removeFromList,
    uploadAtatchment,
    removeAtatchment,
    setFile,
    removeFileByUid,
    resetProcess,
    setProcess,
    setMultilanguageFiles,
    removeMultilanguageFilesByUid,
    setSimpleFile,
    removeSimpleFile,
  };
}