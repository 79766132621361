import { useContext, useEffect, useState } from 'react';

import { Button, Result, Space, Spin} from 'antd';

import AudioForm from '@Components/Forms/AudioForm';
import VideoForm from '@Components/Forms/VideoForm';
import PodcastForm from '@Components/Forms/PodcastForm';
import InfographicForm from '@Components/Forms/InfographicForm';
import MediaDefinition from '@Components/ComposedComponents/MediaDefinition';

import { Modal } from 'antd';

import useCustomPrompt from '@/hooks/useCustomPrompt';
import useNotification from '@/hooks/notifications';

import { ErrorsContext } from '@/context/errorsContext';
import { MediaContext } from '@/context/mediaContext/mediaContext';
import { Navigate, useParams } from 'react-router-dom';


export default function UploadForm ({edit = false}) {
	const {mediaType, category, process: {loading, error, succeed}, resetProcess, setInitialState, getItemData} = useContext(MediaContext);
	const { resetErrors } = useContext(ErrorsContext);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [okCb, setOkCb] = useState(() => {});

	const {openNotification} = useNotification();

	const { type, epId } = useParams();

	useEffect(() => {
		if(type && epId) {
			getItemData({type, epId});
		}
	}, [type, epId]);

	useEffect(resetErrors, [mediaType]);

	useEffect(()=> {
		if (!edit && succeed) {
			resetErrors();
			resetProcess();
			setInitialState();
			openNotification({message: 'Successful upload', description: 'The file has been uploaded successfully.', type: 'success'});
		}
	}, [succeed]);

	useEffect(()=> {
		if (error?.error) {
			openNotification({message: error.status, description: error.title, type: 'error'});
		}
	}, [error]);

	const openModal = (onOk) => {
			setOkCb(() => onOk);

			setIsModalOpen(true);
	};

  const handleOk = () => {
		okCb();
		setInitialState();
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	function goBack () {
		setInitialState();
		resetErrors();
		Navigate(-1);
	}

	useCustomPrompt(openModal, mediaType);

	if (edit && succeed) {
		return (
			<Result
				status="success"
				title="Successful edit!"
				subTitle="The file has been editted successfully."
				extra={[
				<Button type="primary" key="back" onClick={goBack}>
					Go Back
				</Button>
				]}
			/>
		);
	}

	return (
		<Spin spinning={loading}>
			<Modal title="Warning!" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
				<p>You have entered data that will be lost if you exit the page</p>
			</Modal>
			<Space size="large" direction='vertical' style={{width: '100%'}} >
				<h1>{`Media > ${!edit ? 'Upload a new media' : 'Edit media'}`}</h1>
				<MediaDefinition
					exclude={edit ? [] : ['EPV_AUDIO_PODCASTS']}
					edit={edit}
				/>
				{ mediaType ==='EPV_AUDIO' && <AudioForm /> }
				{ mediaType ==='EPV_AUDIO_PODCASTS' && <PodcastForm /> }
				{ mediaType ==='video' && <VideoForm /> }
				{ mediaType ==='EPV_INFOGRAPHICS' && <InfographicForm interactive={category === 'EPV_INFOGRAPHICS_INTERACTIVE'}/> }
			</Space>
		</Spin>
	);
}