import { useRef, useState } from 'react';

import { Select } from 'antd';


import MultiLanguageUpload from '@Components/BasicComponents/MultiLanguageUpload';
import Validate from '@Components/Validate';


import { infographicErrors} from '@/utils/errors/list';
import { checkInfograficNames } from '@/utils/names';

import useIbmS3Uploader from '@/hooks/useIbmS3UploaderNew';
import useNotification from '@/hooks/notifications';
import { useEffect } from 'react';

const tooltips = {
	jpg: 'The filename must use the following convention for infographic files: {referenceID}_{nnn}[{language}-infographics].jpg.',
	png: 'The filename must use the following convention for infographic files: {referenceID}_{nnn}[{language}-infographics].png.',
	vector: 'The filename must use the following convention for infographic files: {referenceID}_{nnn}[{language}-infographics].psd or {referenceID}_{nnn}[{language}-infographics].ai or {referenceID}_{nnn}[{language}-infographics].pdf.'
};

const accept = {
	jpg: 'image/jpeg',
    png: 'image/png',
    vector: '.pdf, .ai, .psd'
};

const types = [
	{label: 'PNG', value: 'png'},
	{label: 'JPEG', value: 'jpg'},
    {label: 'Vector', value:'vector'}
];


export default function InfographicUpload ({selector, ...props}) {
	const {multilanguageFiles, epId, setMultilanguageFiles, removeMultilanguageFilesByUid, countryCode, setCountryCode} = selector();

	const {openNotification} = useNotification();
	const [type, setType] = useState('png');

	const useIbmS3UploaderRef = useRef(
		useIbmS3Uploader({
			setFile: setMultilanguageFiles,
			removeFile: removeMultilanguageFilesByUid,
			bucketPrefix: epId.data + '/infogrphics'
		}
	));
	const {handleUpload, handleRemove, updateBucketPrefix} = useIbmS3UploaderRef.current;

	useEffect(() => {
		updateBucketPrefix(epId.data + '/infogrphics/');
	}, [epId.data]);

	return <>
		<Validate requirements={[infographicErrors]}>
			<MultiLanguageUpload
				value={multilanguageFiles || {}}
				handleUpload={handleUpload}
				beforeUpload={checkInfograficNames({id: epId.data, openNotification})}
				accept={accept[type]}
				handleRemove={handleRemove}
				tooltip={tooltips[type]}
				title="Files upload"
				type={type}
				countryCode={countryCode}
				setCountryCode={setCountryCode}
				component= {
					<Select
						value={type}
						onChange={setType}
						options={types}
						style={{ width: 100 }}
					/>
				}
				{...props}
			/>
		</Validate>
	</>;
}