import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { UserOutlined } from '@ant-design/icons';
import { Button, Form, Input,  Row, Space, Spin, Typography } from 'antd';
const { Title } = Typography;

import * as ROUTES from '@Router/routes';
import authSelector from '@Redux/auth/authSelector';
import useNotification from '@/hooks/notifications';
import { parseRelativeUrl } from '@/utils/settings';

import logo from '@/images/logo-ep-transp-color.png'; 

function ForgotPassword() {
	const navigate = useNavigate();

	const { isAuthenticated, loading, error } = useSelector(authSelector);
	const [email, setEmail] = useState('');
	const {openNotification} = useNotification();

	useEffect(() => {
		if (isAuthenticated) {
			navigate(ROUTES.HOME);
		}
	}, [isAuthenticated]);

	useEffect(() => {
		if (error) {
			openNotification({message: error.status, description: error.title, type: 'error'});
		}
	}, [error]);

	function handleSubmit() {
		console.log(email);
	}
	function handleEmailChange(e) {
		e.preventDefault();
		setEmail(e.target.value);
	}

	return (
		<>
			<Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}}>
				<Space direction='vertical' size='large'> 
					<Space direction='vertical' align="center"> 
						<img src={parseRelativeUrl(logo)} alt='European Parliament Logo' style={{width: 300 }}/>
						<Title>Forgot your password?</Title>
						<Typography>{"Enter your email and we'll send you a link to reset your password."}</Typography>	
					</Space>
					<Spin spinning={loading}>
						<Form
							name="normal_login"
							className="login-form"
							initialValues={{
								emember: true,
							}}
							onFinish={handleSubmit}
						>
							<Form.Item
								name="email"
							>
								<Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" onChange={handleEmailChange} style={{width: '100%' }}/>
							</Form.Item>
							<Form.Item>
								<Button type="primary" htmlType="submit" className="login-form-button" style={{width: '100%' }}>
									Submit
								</Button>
							</Form.Item>
						</Form>
					</Spin>
				</Space>
			</Row>
		</>
	);
}
export default ForgotPassword;