export function getLinksAndAttachments (data) {
    const links = [];
    const attachments = [];

    data?.forEach((item, index) => {
        item.id = index;

        if (item.format === 'link') {
            links.push(item);
        } else {
            attachments.push(item);
        }
    });

    return {
        links: links.length > 0 ? links : null,
        attachments: attachments.length > 0 ? attachments : null,
    };
}

export function transformFile (file) {
    const result = [
        {
        name: file,
        url: file,
        status: 'done',
        lang: file,
        uid: file,
    }];

    return result;
}

export function transformFiles (files) {
    const result = [];
    files.forEach(({description, ref}) => {
        Object.keys(ref).forEach( key => {
            result.push({
                name: description[key],
                url: ref[key],
                status: 'done',
                lang: key,
                uid: key,
            });
        });
    });

    return result;
}

export function transformInfographicFiles (files) {
    const result = {};
    files.forEach(({name, url, format, language}) => {
        if (!result[format]) {
           result[format] = {}; 
        }
        if (!result[format][language]) {
            result[format][language] = [];
        }

        result[format][language].push({
            name,
            url,
            type: format,
            countryCode: language,
            status: 'done',
            uid: name,
        });
    });

    return result;
}

export function transformAttachments (attachments) {
    const result = [];
    attachments.forEach(({description, ref, id}) => {
        const att = {
            description,
            ref: {},
            id
        };
        Object.keys(ref).forEach( key => {
            att.ref[key] ={
                name: description[key],
                url: ref[key],
                status: 'done',
                lang: key,
                uid: key,
            };
        });
        result.push(att);
    });

    return result;
}

export const transformMultilanguageArrayToObject = (data) => {
    const multilangObject = {};

    if (!data || !data.length) return multilangObject;

    data.forEach(({languageIsoCode, value}) => {
        multilangObject[languageIsoCode] = value;
    });

    return multilangObject;
};

export const transformLinks = (data) => {
    return data.map(({descriptions, refs, /* format */}) => {
        const link = {
            description: {},
            ref: {},
            id: {}
        };

        for(let i = 0; i < descriptions.length; i++) {
            let lang = descriptions[i].languageIsoCode;

            link.description[lang] = descriptions[i].value;

            lang = refs[i].languageIsoCode;
            link.ref[lang] = refs[i].value;

            /* if (format === 'attachment') {
                link.id[lang] = refs[i].attachmentId;
            } */
        }

        return link;
    });
};
