import { useParams } from 'react-router-dom';
import { Space, Tabs} from 'antd';

import TopicInfo from '@Components/Topics/Info';
import { TopicContextProvider } from '@/context/topicContext/topicContext';
import AssociationCriteria from '@Components/Topics/AssociationCriteria';
import AssociatedMedia from '@Components/Topics/AssociatedMedia';
import HighlightedMedia from '@Components/Topics/HighlightedMedia';

export default function EditTopic() {
    const { referenceId } = useParams();

    const items = [
        {
            key: '1',
            label: 'TOPIC INFO',
            children: (
                <TopicInfo referenceId={referenceId}/>
            ),
        },
        {
            key: '2',
            label: 'ASSOCIATION CRITERIA',
            children: <AssociationCriteria />,
            destroyInactiveTabPane: true,
            disabled: !referenceId
        },
        {
            key: '3',
            label: 'ASSOCIATED MEDIA',
            children: <AssociatedMedia />,
            destroyInactiveTabPane: true,
            disabled: !referenceId
        },
        {
            key: '4',
            label: 'HIGHLIGHTED MEDIA',
            children: <HighlightedMedia />,
            destroyInactiveTabPane: true,
            disabled: !referenceId
        },
    ];

	return (
		<TopicContextProvider edit>
			<Space size="large" direction='vertical' style={{width: '100%'}} >
				<h1>{`Topics > Edit > ${referenceId}`}</h1>
			</Space>
            <Tabs defaultActiveKey="1" items={items} type="card" size="large"/>
		</TopicContextProvider>
	);
}