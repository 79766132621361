import { Modal, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useRef, useState } from 'react';

const { Dragger } = Upload;

const DragUpload = ({handleUpload, onRemove, value, multiple = true, beforeUpload = null, ...props}) => {
	const [ isModalOpen, setIsModalOpen ] = useState(false);
	const currentFiles = useRef([]);
	const filesToRemove = useRef([]);

	async function upload ({file}) {
		const sameNameFiles = value.filter(f => f.name === file.name);

		if (sameNameFiles.length > 0) {
			currentFiles.current = [...currentFiles.current, file];
			filesToRemove.current = [...filesToRemove.current, sameNameFiles[0]];
			setIsModalOpen(true);
		} else {
			handleUpload({file});
		}
	}

	function handleOk () {
		filesToRemove.current.forEach((file, index) => {
			onRemove(file);
			handleUpload({file: currentFiles.current[index]});
		});
		filesToRemove.current = [];
		currentFiles.current = [];
		setIsModalOpen(false);
	}

	function isImageUrl ({url}) {
		if(!url) return false;
		return url.endsWith('png') || url.endsWith('jpg');
	}

	return (
		<>
			<Modal title="Warning!" open={isModalOpen} onOk={handleOk} onCancel={() => setIsModalOpen(false)}>
				<div>
					<p>Currently there is a file with the same name, do you want to overwrite it? </p>
					{filesToRemove.current.map(file => <p key={file.uid}>{file.name}</p>)}
				</div>
			</Modal>
			<Dragger
				listType="picture"
				fileList={value}
				className='bulk-upload__row'
				multiple={multiple}
				customRequest={upload}
				beforeUpload={beforeUpload}
				onRemove={onRemove}
				isImageUrl={isImageUrl}
				{...props}
			>
				<p className="ant-upload-drag-icon">
					<InboxOutlined />
				</p>
				<p className="ant-upload-text">Click or drag file to this area to upload</p>
			</Dragger>
		</>
	);
};

export default DragUpload;