import { useEffect, useMemo } from 'react';

import { Alert, Input, Pagination, Row, Select, Space } from 'antd';
const { Search } = Input;

import eventInfoSelector from '@Redux/eventInfo/eventInfoSelector';

import AssociatedTable from '@Components/Tables/AssociatedTable';

import useNotification from '@/hooks/notifications';
import useFetchData from '../../hooks/useFetchData';
import { useSelector } from 'react-redux';


const searchTypes = [
	{ label: 'Search by reference ID', value: 'topicKey' },
	{ label: 'Search by keywords', value: 'search' },
];

export default function AssociatedTopicsTable({selected = false, automatic = false}) {
	const {openNotification} = useNotification();

	const {eventId} = useSelector(eventInfoSelector());

	const topicsParams = {url: 'topics', sort: 'modified:desc'};
	const {setParam, setParams, searchParams, data, loading, pagination, error} = useFetchData({params: topicsParams});

	const associatedTopicsParams = useMemo( () => {
		return {url: `event/${eventId}/topics`, automatic: false};
	}, []);
	const associatedTopics = useFetchData({params: associatedTopicsParams, useDefaultParams: true});

	const automaticTopicsParams = useMemo( () => {
		return {url: `event/${eventId}/topics`, automatic: true};
	}, []);
	const automaticTopics = useFetchData({params: automaticTopicsParams, useDefaultParams: true});


    const associatedList = useMemo(() => associatedTopics.data?.map( topic => {
        return {...topic, selected: true};
    }), [associatedTopics]);

		const automaticList = useMemo(() => automaticTopics.data?.map( topic => {
			return {...topic, selected: true, blocked: true};
	}), [automaticTopics]);

	useEffect(() => {
		setParams({sort: 'modified:desc'});
	}, []);

	const displayData = useMemo(() => {
		if (selected) {
			return associatedList;
		}

		if (automatic) {
			return automaticList;
		}

		return data?.map( media => {
			let index = associatedList?.findIndex( a => {
				return a.key == media.key;
			});

			const selected = index !== -1;

			index = automaticList?.findIndex( a => {
				return a.key == media.key;
			});

			const blocked = index !== -1 && index !== undefined;

			return {...media, selected, blocked};
		});
	}, [selected, data, associatedList]);

	[error, associatedTopics.error, automaticTopics.error].forEach((err) => {
		useEffect(() => {
			if (err) {
				openNotification({message: 'Something went wrong!', description: err.message || null, type: 'error'});
			}
		}, [err]);
	});

	const handlePagination = (page, pageSize) => {
		setParams({page, pageSize});
	};

	const handleSearch = () => {
		setParams({sort: 'modified:desc'});
	};

	const getListData = async () => {
		associatedTopics.getData();
	};

	return (
		<Space size="large" direction='vertical' style={{width: '100%'}} >
			{automatic &&
				<Alert
						message="Associated topics by tag cannot be modified from the Multimedia Centre CMS."
						description="Please contact Audiovisual Unit to edit or modify any of the metadata you can find on this tab."
						type="info"
						showIcon
				/>}
			{!selected && !automatic && <Search
				addonBefore={
					<Select
						style={{width: 190}}
						options={searchTypes}
						value={searchParams.get("searchType") || 'search'}
						onChange={setParam('searchType')}
					/>
				}
				value={searchParams.get("search") || ''}
				onChange={setParam('search', false)}
				onSearch={handleSearch}
				enterButton
			/>}
			<>
				<AssociatedTable
					data={displayData}
					loading={loading}
					sort={searchParams.get("sort") || 'modified:desc'}
					setSort={setParam('sort')}
					getListData={getListData}
					type="topics"
					name="topics"
					selectItem={async (id) => await associatedTopics.toggleSelect({id, select: true})}
					unselectItem={async (id) => await associatedTopics.toggleSelect({id, select: false})}
					dispatched={false}
				/>
				{(!selected && !automatic) && <Row justify="center">
					<Pagination
						total={pagination.totalElements}
						pageSize={pagination.pageSize}
						current={pagination.page}
						showSizeChanger
						showQuickJumper
						hideOnSinglePage
						disabled={loading}
						onChange={handlePagination}
					/>
				</Row>}
			</>
		</Space>
	);
}