import { useState, useEffect } from 'react';
import { areSameObject } from '@/utils/basics';

export default function useSeoSync ({selector}) {
	const [syncSeo, setSyncSeo] = useState(false);

	const {seoTitle, seoDescription, seoKeywords, title, description, setValue, ...rest} = selector();

	useEffect(() => {
		if (syncSeo) {
			if(!areSameObject(title, seoTitle)) {
				setValue({param: 'seoTitle', value: {...title}});
			}
			if(!areSameObject(description, seoDescription)) {
				setValue({param: 'seoDescription', value: {...description}});
			}
		}
	}, [title, description, syncSeo]);

	return {syncSeo, setSyncSeo, seoTitle, seoDescription, seoKeywords, setValue, ...rest};
}