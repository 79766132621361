import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Input, Pagination, Radio, Row, Select, Space } from 'antd';
const { Search } = Input;

import { fetchMediaList } from '@Redux/mediaList/mediaListFetchers';
import mediaListSelector from '@Redux/mediaList/mediaListSelector';

import AudioTable from '@Components/Tables/AudioTable';

import useNotification from '@/hooks/notifications';
import useCustomSearchParams from '@/hooks/useSearchParams';

const types = [
	{ label: 'AUDIOS', value: 'audio' },
	{ label: 'PODCASTS', value: 'podcast' },
	{ label: 'VIDEOS', value: 'video' },
	{ label: 'PHOTOS', value: 'photo' },
	{ label: 'PHOTOSETS', value: 'photoset' },
	{ label: 'INFOGRAPHICS', value: 'infographic' },
	{ label: 'STREAMS', value: 'streaming' },
];

const searchTypes = [
	{ label: 'Search by reference ID', value: 'epId' },
	{ label: 'Search by keywords', value: 'search' },
];

export default function MediaList() {
	const { data, loading, pagination, error } = useSelector(mediaListSelector);

	const {openNotification} = useNotification();

	const {setParam, setParams, searchParams, getData, deleteParam} = useCustomSearchParams({get: fetchMediaList});

	useEffect(() => {
		if (error) {
			openNotification({message: 'Something went wrong!', description: error.message || null, type: 'error'});
		}
	}, [error]);
	
	const handleType = ({ target: { value } }) => {
		deleteParam('page');
		setParams({type: value});
	};

	const handlePagination = (page, pageSize) => {
		setParams({page, pageSize});
	};

	const handleSearch = () => {
		setParams({sort: 'relevancy:asc'});
	};
	
	return (
		<Space size="large" direction='vertical' style={{width: '100%'}} >
			<h1>{'Media > View by media type'}</h1>
			<Radio.Group
				options={types}
				onChange={handleType}
				value={searchParams.get("type") || 'audio'} 
				optionType="button"
				size="large"
			/>
			<Search
				addonBefore={
					<Select
						style={{width: 190}}
						options={searchTypes}
						value={searchParams.get("searchType") || 'search'}
						onChange={setParam('searchType')}
					/>
				}
				value={searchParams.get("search") || ''}
				onChange={setParam('search', false)}
				onSearch={handleSearch}
				enterButton
			/>
			<>
				<AudioTable
					data={data}
					loading={loading}
					sort={searchParams.get("sort") || 'mediaDate:desc'}
					setSort={setParam('sort')}
					getListData={getData}
					type={searchParams.get("type") || 'audio'}
				/>
				<Row justify="center">
					<Pagination
						total={pagination.totalElements}
						pageSize={pagination.pageSize}
						current={pagination.page}
						showSizeChanger
						showQuickJumper
						hideOnSinglePage
						disabled={loading}
						onChange={handlePagination}
					/>
				</Row>
			</> 
		</Space>
	);
}