export const fullToSimpleLanguage = {
	el_GR: 'el',
	it_IT: 'it',
	pl_PL: 'pl',
	ro_RO: 'ro',
	sv_SE: 'sv',
	da_DK: 'da',
	mt_MT: 'mt',
	bg_BG: 'bg',
	fr_FR: 'fr',
	cs_CZ: 'cs',
	de_DE: 'de',
	hu_HU: 'hu',
	hr_HR: 'hr',
	fi_FI: 'fi',
	en_GB: 'en',
	et_EE: 'et',
	lv_LV: 'lv',
	lt_LT: 'lt',
	ga_IE: 'ga',
	sk_SK: 'sk',
	es_ES: 'es',
	sl_SI: 'sl',
	nl_NL: 'nl',
	pt_PT: 'pt',
};

export const shortLanguages = [
	'el',
	'it',
	'pl',
	'ro',
	'sv',
	'da',
	'mt',
	'bg',
	'fr',
	'cs',
	'de',
	'hu',
	'hr',
	'fi',
	'en',
	'et',
	'lv',
	'lt',
	'ga',
	'sk',
	'es',
	'sl',
	'nl',
	'pt'
];

export const simpleToFullLanguage = {
	el: 'el_GR',
	it: 'it_IT',
	pl: 'pl_PL',
	ro: 'ro_RO',
	sv: 'sv_SE',
	da: 'da_DK',
	mt: 'mt_MT',
	bg: 'bg_BG',
	fr: 'fr_FR',
	cs: 'cs_CZ',
	de: 'de_DE',
	hu: 'hu_HU',
	hr: 'hr_HR',
	fi: 'fi_FI',
	en: 'en_GB',
	et: 'et_EE',
	lv: 'lv_LV',
	lt: 'lt_LT',
	ga: 'ga_IE',
	sk: 'sk_SK',
	es: 'es_ES',
	sl: 'sl_SI',
	nl: 'nl_NL',
	pt: 'pt_PT',
};