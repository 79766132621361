import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export function utcToLocalTime(utc) {
	return dayjs(utc).local();
}

export function localTimeToUtc(localTime) {
	return dayjs(localTime).utc();
}

export function localTimeFormated (utc, time = false) {
	if (!utc) return '-';

	let format = 'DD-MM-YYYY';

	if (time) {
		format = 'DD-MM-YYYY HH:mm';
	}

	return dayjs(utc).local().format(format);
}

export function dateToISOString (date) {
	return new Date(date).toISOString();
}