import React from 'react';

import { Space, Typography } from 'antd';

const { Title } = Typography;

export default function UnderConstruction () {

	return (
		<Space size="large">
			<Title>Under construction.</Title>
		</Space>
	);
}