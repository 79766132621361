import TopicForm from '../../components/Forms/TopicForm';
import { TopicContextProvider } from '../../context/topicContext/topicContext';

export default function Create () {

  return (
    <TopicContextProvider>
      <TopicForm/>
    </TopicContextProvider>
	);
}