import { createContext } from "react";
import {  useSelector } from "react-redux";

import authSelector from '@Redux/auth/authSelector';

import initialData from "./initialData";
import useContextState from "@/hooks/useContextState";

import { postData, getData, putData } from '@/utils/api';
import { transformTopicToUpload } from "@/utils/transformData/toFetch";
import { transformFetchedTopic } from "../../utils/transformData/fetched/transform";

export const TopicContext = createContext({
	...initialData,
	countryCode: 'en_GB'
});

const initialStateData = JSON.parse(JSON.stringify(initialData));

export function TopicContextProvider({ edit = false, children }) {
  initialData.process.edit = edit;
  const dataAndFunctions = useContextState({initialData, initialStateData});
  const { data, setProcess, setData } = dataAndFunctions;
  const { jwttoken: auth } = useSelector(authSelector);

  const upload = async () => {
    setProcess({
      succeed: false,
      loading: true,
      error: null,
    });

    const func = data.process.edit ? putData : postData;
    const endpoint = data.process.edit ? `/topics/${data.topicId}` : `/topics`;

		const fetchData = async () => {
			try {
				const response = await func({auth, data: transformTopicToUpload(data), endpoint});

				if (response.error) {
					throw(new Error(response.message));
				}

				setProcess({
          loading: false,
					error: null,
					succeed: true
        });

        if (data.process.edit) {
          setData( (currentData) => {
            return {
              ...currentData,
              ...transformFetchedTopic(response.content),
              topicId: data.topicId

            };
          });
        }

			} catch (err) {
				setProcess({
          loading: false,
					error: err || 'Something went wrong',
        });
			}
		};

		return await fetchData();
	};

  const getTopicData = async (referenceId) => {
    setProcess({
      loading: true,
      error: null,
    });

    try {
      const response = await getData({auth, endpoint: `/topics/${referenceId}`});

      if (response.error) {
        throw response.message;
      }

      setProcess({
        loading: false,
        error: null
      });

      setData( (currentData) => {
        return {
          ...currentData,
          ...transformFetchedTopic(response.content)
        };
      });

    } catch (err) {
      setProcess({
        loading: false,
        error: err || 'Something went wrong',
      });
    }
  };

  const getTopicAssociationCriteria = async () => {
    setProcess({
      loading: true
    });

    try {
      const response = await getData({auth, endpoint: `/topics/${data.topicId}/association/criteria`});

      return response;

    } finally {
      setProcess({
        loading: false
      });
    }
  };

  const setTopicAssociationCriteria = async (associationCriteria) => {
    setProcess({
      loading: true
    });

    try {
      const response = await putData({auth, data: associationCriteria, endpoint: `/topics/${data.topicId}/association/criteria`});

      return response;

    } finally {
      setProcess({
        loading: false
      });
    }
  };

  return (
    <TopicContext.Provider
      value={{
        ...dataAndFunctions,
        title: dataAndFunctions.title,
        upload,
        getTopicData,
        getTopicAssociationCriteria,
        setTopicAssociationCriteria
      }}
    >
    {children}
    </TopicContext.Provider>
  );
}