import { createSlice } from '@reduxjs/toolkit';

export const errorsInitialState = {
	verifyFunctions: {}
};

export const uploadErrorsSlice = createSlice({
	name: 'uploadErrors',
	initialState: {...errorsInitialState},
	reducers: {
		resetErrors: () => errorsInitialState,
		addVerifyFunction: (state, action) => {
			const {id, func} =action.payload;
			state.verifyFunctions[id] = func;
		}
	},
});

export const { resetErrors, addVerifyFunction} = uploadErrorsSlice.actions;

export default uploadErrorsSlice.reducer;