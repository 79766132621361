import { useState } from "react";
import { Select, Spin, Tabs } from "antd";
import HighlightedMediasTable from "@Components/Tables/HighlightedMediasTable";
import OrderHighlightedMedias from "@Components/Tables/OrderHighlightedMedias";

import eventInfoSelector from '@Redux/eventInfo/eventInfoSelector';
import { useSelector } from "react-redux";

const options = [
    {label: 'UNSELECTED MEDIA', value: 'unselected'},
    {label: 'SELECTED MEDIA', value: 'selected'}
];

export default function HighlightedMedia () {
    const [option, setOption] = useState('selected');

    const {eventId} = useSelector(eventInfoSelector());

    const items = [
        {
            key: '1',
            label: <span>HIGHLIGHTED MEDIA <Select
                    options={options}
                    value={option}
                    onChange={setOption}
                    style={{width: 200, marginLeft: 10}}
                /></span>,
            children: <HighlightedMediasTable
                selected={option === 'selected'}
                url={`event/${eventId}/medias`}
            />,
        },
        {
            key: '2',
            label: 'ORDER HIGHLIGHTED MEDIA',
            children: <OrderHighlightedMedias url={`event/${eventId}/medias`} />,
            destroyInactiveTabPane: true,
        },
    ];

    return<Spin spinning={false}>
            <Tabs defaultActiveKey="1" items={items} size="large" destroyInactiveTabPane />
    </Spin>;
}